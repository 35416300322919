import * as React from 'react';

const AttachmentIcon = () => {
  return (
    <svg fill="none" height={14} width={13}>
      <path
        d="M1.02 1.02a3.491 3.491 0 014.932 0l5.193 5.193a.41.41 0 11-.58.58L5.372 1.6C4.364.593 2.608.593 1.6 1.6a2.67 2.67 0 000 3.771l7.251 7.333c.62.62 1.701.62 2.321 0 .64-.64.64-1.68 0-2.32L4.212 3.34a.63.63 0 00-.871 0c-.24.24-.24.631 0 .871l6.67 6.753c.139.138.139.441 0 .58a.41.41 0 01-.58 0L2.761 4.79c-.56-.56-.56-1.47 0-2.03.542-.543 1.489-.542 2.03 0l6.961 7.042a2.478 2.478 0 010 3.481 2.44 2.44 0 01-1.74.716 2.44 2.44 0 01-1.74-.716L1.02 5.952a3.49 3.49 0 010-4.932z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default AttachmentIcon;
