import * as React from 'react';

const SeventeenthPermitIcon = () => {
  return (
    <svg fill="none" height={56} width={44} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1h41v54H1z" fill="#FFF500" />
      <g clipPath="url(#a)">
        <path
          d="M0 55.198V.802A.8.8 0 0 1 .802 0h41.602a.8.8 0 0 1 .801.802v54.403a.8.8 0 0 1-.801.801H.802A.81.81 0 0 1 0 55.198ZM41.602 1.597H1.597v52.8h40v-52.8h.005Z"
          fill="#096BEF"
        />
        <path
          d="M8.75 36.435h25.7c.388 0 .703-.358.703-.801 0-.444-.315-.802-.703-.802H8.75c-.389 0-.703.358-.703.802 0 .444.314.801.703.801ZM8.75 41.917h25.7c.388 0 .703-.358.703-.802 0-.444-.315-.801-.703-.801H8.75c-.389 0-.703.357-.703.801 0 .444.314.802.703.802ZM8.75 47.035h25.7c.388 0 .703-.358.703-.802 0-.444-.315-.802-.703-.802H8.75c-.389 0-.703.358-.703.802 0 .444.314.802.703.802ZM8.047 30.096V7.652c0-.425.345-.77.77-.77h25.565c.425 0 .77.345.77.77v22.444c0 .426-.345.771-.77.771H8.817a.771.771 0 0 1-.77-.77ZM33.61 8.423H9.588v20.903h24.023V8.423Z"
          fill="#096BEF"
        />
        <path d="M33.611 8.423H9.588v20.903h24.023V8.423Z" fill="#fff" />
        <path
          d="M21.6 22.093a4.07 4.07 0 0 1-4.064-4.064 4.07 4.07 0 0 1 4.064-4.063 4.071 4.071 0 0 1 4.063 4.063 4.071 4.071 0 0 1-4.063 4.064Zm0-6.585a2.521 2.521 0 1 0 0 5.043 2.521 2.521 0 1 0 0-5.043ZM15.131 29.326c.346-2.584 3.114-4.6 6.469-4.6 3.354 0 6.122 2.016 6.468 4.6h1.547c-.339-3.435-3.798-6.142-8.015-6.142-4.218 0-7.677 2.713-8.016 6.142h1.547Z"
          fill="#096BEF"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h43.199v56H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SeventeenthPermitIcon;
