import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useApproverStepsHooks = ({
  manual = true,
  permitApplicationLogId,
  token,
}) => {
  const [{ data, loading, error }, executeApproverSteps] = useHooks(
    {
      url: `${endpoint}/api/approver/steps`,
      params: {
        permitApplicationLogId: permitApplicationLogId,
      },
      headers: { Authorization: `Bearer ${token}` },
    },
    { manual, useCache: false }
  );

  return { data, loading, error, executeApproverSteps };
};

export default useApproverStepsHooks;
