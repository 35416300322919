export const applicantUserRoleId = 1;

export const pages = {
  DASHBOARD: 'dashboard',
  FLOW: 'flow',
  HOME: 'home',
  PERMITS: 'permits',
  REVIEW: 'review',
  VISITOR: 'visitor',
};

export const userTypeValue = {
  APPLICANT: 'applicant',
  APPROVER: 'approver',
  VISITOR: 'visitor',
};
