import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useMessagesHooks = ({ manual = true, token, groupChatId }) => {
  const [{ data, loading, error }, executeMessages] = useHooks(
    {
      url: `${endpoint}/api/chats/messages`,
      params: {
        groupChatId,
      },
      headers: { Authorization: `Bearer ${token}` },
    },
    { manual, useCache: true }
  );

  return { data, loading, error, executeMessages };
};

export default useMessagesHooks;
