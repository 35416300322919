import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';

import { applicantUserRoleId } from 'common/utils/constants';
import { useUserRoleHooks } from 'services';
import formState from 'common/data/formState';
import getLoginApi from 'services/users/getLoginApi';

import Loading from 'common/components/Loading';

const PageLayout = ({ children }) => {
  const { token } = QueryString.default.parse(global.location.hash);
  const sessionToken = localStorage.getItem('token');
  const [values, setValues] = useRecoilState(formState);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { executeUserRole } = useUserRoleHooks();

  const loginData = useCallback(async () => {
    try {
      const response = await getLoginApi(token);

      if (response?.jwt) {
        localStorage.setItem('token', response.jwt);

        global.location = global.location.pathname;
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [token]);

  const getAuthInfo = useCallback(async () => {
    try {
      const bearerTokenDecode = jwt_decode(sessionToken);

      const date = new Date();
      const current = date.getTime().toString().substring(0, 10);
      const secondsLeft = bearerTokenDecode.exp - current;

      if (secondsLeft <= 0) {
        localStorage.removeItem('token');

        global.location = global.location.pathname;
      }

      const role = await executeUserRole({
        headers: { Authorization: `Bearer ${sessionToken}` },
      });

      const isApplicant = (role?.data || []).some(
        ({ userRoleGroup }) =>
          userRoleGroup?.userRoleGroupId === applicantUserRoleId
      );

      setValues(state => ({
        ...state,
        accessToken: sessionToken,
        user: bearerTokenDecode,
        userRole: role?.data,
        userType: isApplicant ? 'applicant' : 'approver',
      }));

      if (!isApplicant) {
        navigate('/dashboard');
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [executeUserRole, navigate, sessionToken, setValues]);

  useEffect(() => {
    if (token && !sessionToken) {
      loginData();
    }
  }, [sessionToken, loginData, token]);

  useEffect(() => {
    if (sessionToken && values.userType === 'visitor') {
      getAuthInfo();
    }
  }, [getAuthInfo, sessionToken, values.userType]);

  useEffect(() => {
    if (!sessionToken && !token) {
      setLoading(false);
    }
  }, [sessionToken, token]);

  if (isLoading) return <Loading />;

  return <>{children}</>;
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
