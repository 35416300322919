import { useRecoilValue } from 'recoil';

import { resourceActions } from 'common/data/permissionAccess';
import formState from 'common/data/formState';
import RedirectToFlow from 'common/components/RedirectToFlow';

export const withPermissions = (Component, userType, page) => {
  return props => {
    const values = useRecoilValue(formState);
    const actions = resourceActions(userType);

    if (values.userType !== userType || !actions?.permissions?.includes(page)) {
      return <RedirectToFlow />;
    }

    return <Component {...props} />;
  };
};
