import * as React from 'react';

const FirstPermitIcon = () => {
  return (
    <svg fill="none" height={55} width={60}>
      <rect fill="#096BEF" height={2} rx={1} width={28} x={16} y={52.8} />
      <path
        d="M24.4 43l-2.8 10.5h16.8L34.9 43H24.4z"
        fill="#fff"
        stroke="#096BEF"
        strokeWidth={2}
      />
      <rect
        fill="#FFF500"
        height={44.23}
        rx={3}
        stroke="#096BEF"
        strokeWidth={2}
        width={58}
        x={1}
        y={1}
      />
      <path
        d="M16.896 17.288h2.064l4.928 11.328h-2.816l-.976-2.4h-4.4l-.944 2.4H12l4.896-11.328zm.96 3.296l-1.376 3.52h2.768l-1.392-3.52zM26.627 17.288h3.744c.907 0 1.766.101 2.576.304.822.203 1.536.533 2.144.992a4.681 4.681 0 011.44 1.76c.363.725.544 1.605.544 2.64 0 .917-.176 1.728-.528 2.432a5.23 5.23 0 01-1.392 1.76 6.274 6.274 0 01-2.032 1.072 7.893 7.893 0 01-2.416.368h-4.08V17.288zm2.496 9.024h1.296c.576 0 1.11-.059 1.6-.176a3.51 3.51 0 001.296-.576c.363-.277.646-.635.848-1.072.214-.448.32-.992.32-1.632 0-.555-.106-1.035-.32-1.44a2.802 2.802 0 00-.832-1.024 3.56 3.56 0 00-1.248-.592 5.225 5.225 0 00-1.488-.208h-1.472v6.72zM45.968 20.056a1.725 1.725 0 00-.832-.56 2.78 2.78 0 00-.976-.192c-.181 0-.368.021-.56.064a1.98 1.98 0 00-.544.208c-.17.085-.31.203-.416.352a.911.911 0 00-.16.544c0 .341.128.603.384.784.256.181.576.336.96.464.395.128.816.256 1.264.384.448.128.864.31 1.248.544.395.235.72.55.976.944.256.395.384.923.384 1.584 0 .63-.117 1.179-.352 1.648-.235.47-.555.859-.96 1.168a3.96 3.96 0 01-1.392.688 6.031 6.031 0 01-1.664.224 6.065 6.065 0 01-2.048-.336 5.133 5.133 0 01-1.76-1.088l1.776-1.952c.256.341.576.608.96.8a2.884 2.884 0 001.824.208c.213-.053.4-.128.56-.224.17-.096.304-.219.4-.368a.884.884 0 00.16-.528.93.93 0 00-.4-.8 3.353 3.353 0 00-.976-.512 30.493 30.493 0 00-1.28-.416 5.748 5.748 0 01-1.28-.56 3.167 3.167 0 01-.992-.928c-.256-.384-.384-.89-.384-1.52 0-.608.117-1.141.352-1.6.245-.459.565-.843.96-1.152.405-.31.87-.539 1.392-.688a5.49 5.49 0 011.616-.24c.64 0 1.259.09 1.856.272a4.28 4.28 0 011.616.912l-1.712 1.872z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default FirstPermitIcon;
