import * as React from 'react';

const TwelvethPermitIcon = () => {
  return (
    <svg fill="none" height={57} width={56} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 55.5V1h30v13.5h14.5v8l-4.5 14 .5 11 4-3.5v11.5H1Z"
        fill="#FFF500"
      />
      <path
        d="M8.052 16.451h13.162c.546 0 .91-.364.91-.91V6.215c0-.546-.364-.91-.91-.91H8.052c-.547 0-.911.364-.911.91v9.326c-.012.461.45.91.91.91Zm.91-9.325h11.341v7.492H8.963V7.126ZM6.215 22.622H34.01c.546 0 .91-.364.91-.91 0-.547-.364-.911-.91-.911H6.215c-.546 0-.91.364-.91.91 0 .547.364.911.91.911ZM6.215 29.298H34.01c.546 0 .91-.364.91-.91 0-.547-.364-.911-.91-.911H6.215c-.546 0-.91.364-.91.91 0 .547.364.91.91.91ZM6.215 35.966H34.01c.546 0 .91-.364.91-.91 0-.547-.364-.911-.91-.911H6.215c-.546 0-.91.364-.91.91 0 .547.364.91.91.91ZM34.835 41.731c0-.546-.365-.91-.911-.91H6.215c-.546 0-.91.364-.91.91s.364.91.91.91H34.01c.461 0 .826-.46.826-.91Z"
        fill="#096BEF"
      />
      <path
        d="m53.39 12.98-1.458-.461c-.364-.098-.728-.183-1.19-.183-1.65 0-3.108 1.093-3.654 2.647l-.826 2.465v-1.906c0-.28-.182-.546-.364-.729L30.902.28A1.008 1.008 0 0 0 30.259 0H.91C.364 0 0 .364 0 .91v54.762c0 .547.364.91.91.91h44.43c.545 0 .91-.363.91-.91V43.336l2.562-3.473.097-.28 6.86-21.855c.729-2.004-.364-4.104-2.38-4.748ZM31.18 3.108l11.973 11.523H31.18V3.108Zm13.345 51.654H1.833V1.822h27.515v13.72c0 .546.364.91.91.91h14.268v6.861L40.227 36.84v.279l.182 11.608c0 .364.28.728.644.826.364.097.826 0 1.008-.365l2.465-3.29v8.863Zm-2.38-8.864-.182-7.59 4.565 1.458-4.383 6.132Zm11.972-28.705-6.581 20.934-5.306-1.737 6.593-20.848c.364-1.008 1.457-1.651 2.465-1.275l1.457.462c1.105.267 1.748 1.457 1.372 2.464Z"
        fill="#096BEF"
      />
      <path
        d="M32.25 52.893c-.17 0-.34-.024-.51-.06a2.16 2.16 0 0 1-1.615-1.518l-.292-.935-1.627 1.445a3.666 3.666 0 0 1-2.744.947 2.632 2.632 0 0 1-1.93-1.032c-1.081.4-2.198.692-3.327.862a.838.838 0 0 1-.947-.716.838.838 0 0 1 .716-.948h.012a16.83 16.83 0 0 0 2.89-.74 3.896 3.896 0 0 1-.06-.838 5.311 5.311 0 0 1 2.343-4.02c.85-.57 2.003-.34 2.574.51.218.329.328.717.315 1.118-.072 1.882-1.347 3.157-2.84 4.019.133.073.29.121.448.134a2.04 2.04 0 0 0 1.47-.535l1.627-1.432a1.685 1.685 0 0 1 2.367.157c.158.17.268.377.34.608l.292.934c.085.292.388.45.68.365a.468.468 0 0 0 .23-.146l1.47-1.554a2.148 2.148 0 0 1 3.035-.085c.389.364.632.862.668 1.396l.012.194a.841.841 0 0 1-1.675.134l-.012-.194a.455.455 0 0 0-.498-.425.451.451 0 0 0-.304.146l-1.47 1.554c-.448.4-1.031.655-1.638.655Zm-6.072-6.204a.138.138 0 0 0-.097.036 3.556 3.556 0 0 0-1.59 2.708 3.274 3.274 0 0 0 1.87-2.55.194.194 0 0 0-.098-.17c-.036-.012-.06-.024-.085-.024Z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default TwelvethPermitIcon;
