import useHooks from 'axios-hooks';

const useDeletePermitHooks = () => {
  const [{ data, loading, error }, executeDeletePermit] = useHooks({
    manual: true,
  });

  return { data, loading, error, executeDeletePermit };
};

export default useDeletePermitHooks;
