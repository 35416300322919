import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useUploadDocsHooks = () => {
  const [{ data, loading, error }, executeUploadDocs] = useHooks(
    {
      url: `${endpoint}/api/docs/upload`,
      method: 'POST',
    },
    { manual: true }
  );

  return { data, loading, error, executeUploadDocs };
};

export default useUploadDocsHooks;
