/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

import Loading from './Loading';

const RedirectToFlow = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);

  return <Loading />;
};

export default RedirectToFlow;
