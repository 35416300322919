import Alert from '@mui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error) {
    // Log the error to an error reporting service
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          Sorry, something went wrong. Please try again later.
        </Alert>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
