import * as React from 'react';

const PdfModalIcon = () => {
  return (
    <svg fill="none" height={56} width={50}>
      <path d="M8 54.12V1.854h30l11 10.951V54.12H8z" fill="#FFF500" />
      <path
        d="M37.41 22.349c-.862 0-1.941.143-3.236.358-1.727-1.862-3.598-4.512-4.82-7.162 1.294-5.372.647-6.088.36-6.446-.289-.358-.72-1.003-1.224-1.003-.216 0-.72.072-.935.143-.576.215-.863.573-1.08 1.146-.647 1.576.216 4.226 1.224 6.303-.792 3.223-2.159 7.09-3.598 10.17-3.597 1.648-5.539 3.295-5.683 4.799-.072.573.072 1.36 1.08 2.149.287.214.575.286.934.286.864 0 1.655-.645 2.662-2.005.72-1.003 1.511-2.364 2.302-4.011 2.518-1.146 5.684-2.149 8.418-2.65 1.51 1.432 2.877 2.148 4.029 2.148.863 0 1.582-.358 2.086-1.146.575-.787.647-1.432.36-2.077-.504-.644-1.44-1.002-2.878-1.002zm-17.05 9.167c-.431-.358-.431-.573-.431-.644.072-.502.863-1.433 2.95-2.579-1.583 2.794-2.375 3.152-2.519 3.223zm7.77-21.773c.072 0 1.008 1.075.072 3.08-1.295-1.36-.144-3.008-.072-3.08zM26.188 25c.935-2.292 1.87-4.799 2.518-7.162 1.079 1.933 2.374 3.796 3.669 5.228-2.015.501-4.173 1.146-6.187 1.934zm12.302-.502c-.288.43-.935.43-1.151.43-.504 0-.72-.286-1.51-.931.647-.072 1.222-.072 1.726-.072.863 0 1.007.144 1.15.215 0 .072-.071.215-.215.358z"
        fill="#096BEF"
      />
      <path
        d="M49.424 11.964L38.344.934A1.97 1.97 0 0036.978.36H9.28c-1.223 0-2.518.93-2.518 2.936V53.72c0 .86.791 1.72 1.87 2.006h.144c.144 0 .36.071.504.071H47.48c.144 0 .288 0 .504-.071h.144c1.079-.287 1.87-1.146 1.87-2.006V13.826c0-.788-.072-1.361-.575-1.862zm-10.864-.215V4.372l7.482 7.377H38.56zm9.209 41.54c0 .072-.072.215-.144.215H9.28h-.144c-.072-.071-.144-.143-.144-.215V3.297c0-.215.072-.716.288-.716h27.05V13.97H47.77v39.32z"
        fill="#096BEF"
      />
      <path d="M29.93 37.604H2.015v10.887h27.913V37.604z" fill="#fff" />
      <path
        d="M29.712 50.496H2.158A2.16 2.16 0 010 48.347V37.82c0-1.218 1.007-2.149 2.158-2.149h27.554a2.16 2.16 0 012.159 2.15v10.527c.072 1.146-.936 2.15-2.159 2.15zM2.158 37.604c-.072 0-.144.072-.144.143v10.6c0 .072.072.144.144.144h27.554c.072 0 .144-.072.144-.144V37.82c0-.072-.072-.143-.144-.143H2.158v-.072z"
        fill="#096BEF"
      />
      <path
        d="M10.862 40.111a1.718 1.718 0 00-.791-.43c-.288-.071-.576-.143-.864-.143H7.121v7.019h1.151V44.05h.863c.36 0 .72-.071 1.008-.143.287-.143.575-.286.791-.43.216-.215.36-.43.504-.716.143-.286.215-.645.215-1.003 0-.358-.072-.644-.215-.93-.144-.287-.36-.502-.576-.717zm-.432 2.292c-.072.215-.143.358-.287.43-.144.143-.216.215-.36.215-.144.071-.288.071-.432.071H8.272v-2.793h.863c.288 0 .504.072.72.143.144.072.288.215.431.358l.216.43c0 .143.072.287.072.43-.072.286-.072.573-.144.716zM17.841 40.54c-.288-.286-.647-.572-1.15-.715-.432-.215-1.008-.287-1.583-.287h-2.159v7.019h3.094c.216 0 .36-.072.647-.143.216-.072.432-.215.72-.358a2.74 2.74 0 00.647-.645c.216-.286.36-.645.504-1.003.144-.358.216-.93.216-1.504 0-.43-.072-.86-.216-1.217a6.178 6.178 0 00-.72-1.146zm-.863 4.441c-.36.502-.935.717-1.655.717h-1.15v-5.3h.647c.575 0 1.007.071 1.367.214.36.144.647.358.791.573.144.215.36.502.432.788.072.287.072.573.072.86 0 .93-.144 1.647-.504 2.148zM20.36 46.557h1.15v-3.151h3.022v-.788h-3.021v-2.22h3.309v-.86h-4.46v7.019z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default PdfModalIcon;
