const uploadFilesSchema = payload => {
  const { permitApplicationDocumentId, permitApplicationLogId, url } = payload;

  return {
    permitApplicationDocumentId,
    permitApplicationLogId,
    url,
  };
};

export default uploadFilesSchema;
