import { format, formatDistance, formatRelative } from 'date-fns';

export const daysAgo = date => {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  });
};

export const daysAfterAgo = date => {
  const days = formatRelative(new Date(date), new Date());
  return format(new Date(days), 'PP');
};

export const dayConverter = () => {
  const date = new Date();
  const hours = date.getHours();
  const message =
    hours < 12
      ? 'Good morning'
      : hours < 18
      ? 'Good afternoon'
      : 'Good evening';

  return message;
};

export const dateFormat = (date, display = 'Pp') => {
  return format(new Date(date), display);
};
