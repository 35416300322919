import * as React from 'react';

const TenthPermitIcon = () => {
  return (
    <svg fill="none" height={57} width={56}>
      <path
        d="M5.793 26.552c5.15.804 16.606 2.413 21.24 2.413 4.635 0 17.058-1.609 22.69-2.413.644 1.287 1.834 4.634 1.448 7.724-.483 3.862-3.379 9.172-4.827 10.137C44.895 45.38 30.896 56 28.482 56 26.068 56 9.655 44.896 7.724 42.965c-1.931-1.93-2.897-5.793-3.38-7.724-.385-1.545.805-6.436 1.449-8.69zM1.448 11.586L27.034.966l27.516 10.62-2.896 10.621c-6.92.965-21.53 2.8-24.62 2.414-3.09-.386-16.414-1.77-22.69-2.414l-2.896-10.62z"
        fill="#FFF500"
      />
      <path
        d="M25.586 11.104l2.413-2.897 1.931 2.897 4.345.965-3.38 2.897.966 4.345L28 17.38l-4.344 1.93.965-4.344-3.38-2.897 4.346-.965z"
        fill="#fff"
      />
      <path
        d="M27.655 56.758a8.692 8.692 0 01-5.248-1.726L9.771 45.71c-4.902-3.59-7.25-10.081-5.87-16.226.208-1.036.277-2.003.139-2.9-.069-.346.069-.69.345-.967.276-.276.622-.345.967-.276 15.467 2.693 30.658 2.693 45.158.069.345-.07.69.069.967.276a.899.899 0 01.345.898 4.64 4.64 0 00.207 1.933c1.795 6.49-.552 13.326-5.87 17.055l-13.395 9.529c-1.588 1.105-3.383 1.657-5.11 1.657zm-21.267-29c0 .759-.138 1.45-.277 2.209-1.173 5.248.83 10.91 4.972 13.948l12.636 9.252c2.348 1.727 5.386 1.727 7.802.07l13.327-9.391c4.488-3.176 6.56-9.184 5.04-14.707a3.91 3.91 0 01-.207-1.243c-13.947 2.347-28.517 2.278-43.293-.139zM28.621 25.273c-8.078 0-16.295-.76-24.581-2.28-.414-.068-.76-.344-.829-.759L.035 11.808c-.138-.553.138-1.174.69-1.381L27.449.07c.276-.07.552-.07.828 0l26.998 10.426c.553.207.829.829.69 1.381L52.79 22.372c-.139.415-.415.69-.829.76-7.665 1.38-15.398 2.14-23.339 2.14zm-23.545-4.35c15.674 2.83 31.072 2.83 45.78.068l2.692-8.838L27.862 2.28l-25.41 9.874 2.624 8.77zm18.712-.07c-.207 0-.483-.069-.621-.207a1.145 1.145 0 01-.415-1.105l.69-4.074-2.968-2.9c-.276-.276-.415-.76-.276-1.104.138-.415.483-.69.897-.76l4.074-.621 1.795-3.729c.207-.345.553-.621.967-.621.414 0 .828.207.967.621l1.795 3.729 4.074.621c.414.07.76.345.897.76.139.414 0 .828-.276 1.105l-2.969 2.9.69 4.073c.07.415-.068.829-.414 1.105-.345.276-.76.276-1.174.07l-3.66-1.934-3.52 1.933c-.208.138-.346.138-.553.138zM28 16.434c.207 0 .345.07.483.138l2.21 1.174-.414-2.417c-.07-.345.069-.69.345-.966l1.795-1.726-2.417-.346c-.345-.069-.69-.276-.828-.621l-1.105-2.21-1.174 2.21c-.138.345-.483.552-.828.621l-2.417.345 1.795 1.727c.276.276.345.621.345.966l-.414 2.417 2.21-1.174c.069-.069.207-.138.414-.138z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default TenthPermitIcon;
