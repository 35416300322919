import * as React from 'react';

const ThirdPermitIcon = () => {
  return (
    <svg fill="none" height={48} width={100}>
      <path d="M1.5 40V2H70v38H19s-.5-6-6.5-6-7 6-7 6h-4z" fill="#FFF500" />
      <path
        d="M70.74 0H1.129C.504 0 0 .504 0 1.128v39.526c0 .625.504 1.129 1.128 1.129h4.175c.541 3.444 3.527 6.092 7.13 6.092 3.603 0 6.582-2.648 7.13-6.092h51.185c.625 0 1.129-.504 1.129-1.129V1.128A1.138 1.138 0 0070.74 0zM12.434 45.626a4.97 4.97 0 01-4.964-4.964c0-2.738 2.226-4.972 4.964-4.972a4.97 4.97 0 014.965 4.972 4.964 4.964 0 01-4.965 4.964zm57.18-6.1h-50.05c-.54-3.445-3.527-6.1-7.13-6.1-3.603 0-6.581 2.648-7.13 6.1H2.256V2.256h67.357v37.27z"
        fill="#096BEF"
      />
      <circle
        cx={59.541}
        cy={40.617}
        fill="#fff"
        r={6.121}
        stroke="#096BEF"
        strokeWidth={2.2}
      />
      <path
        d="M99.082 22.836l-7.078-1.4-4.061-11.116a1.137 1.137 0 00-1.06-.745h-11.81c-.624 0-1.128.504-1.128 1.128v29.951c0 .625.504 1.129 1.128 1.129h3.4c.542 3.445 3.528 6.092 7.13 6.092 3.603 0 6.582-2.647 7.131-6.092h6.138c.624 0 1.128-.504 1.128-1.129V23.941a1.136 1.136 0 00-.918-1.105zm-13.486 22.79a4.97 4.97 0 01-4.964-4.964c0-2.738 2.226-4.972 4.964-4.972a4.97 4.97 0 014.964 4.972 4.964 4.964 0 01-4.964 4.964zm12.14-6.1h-5.01c-.541-3.445-3.527-6.1-7.13-6.1-3.603 0-6.581 2.648-7.13 6.1h-2.272V11.84h9.898l4.01 10.974c.135.369.45.647.842.722l6.792 1.34v14.651z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default ThirdPermitIcon;
