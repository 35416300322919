import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useRequirementsHooks = ({
  manual = true,
  permitId,
  subStepId,
  token,
}) => {
  const [{ data, loading, error }, executeRequirements] = useHooks(
    {
      url: `${endpoint}/api/applicants/requirements`,
      params: {
        permitApplicationStepId: subStepId,
        permitApplicationLogId: permitId,
      },
      headers: { Authorization: `Bearer ${token}` },
    },
    { manual, useCache: true }
  );

  return { data, loading, error, executeRequirements };
};

export default useRequirementsHooks;
