import { atom, selector } from 'recoil';

const tableState = atom({
  key: 'TableValues',
  default: selector({
    key: 'TableValues/Default',
    get: () => {
      return {
        currentPage: 0,
        items: [],
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
      };
    },
  }),
});

export default tableState;
