import * as React from 'react';

const UncheckIcon = () => {
  return (
    <svg fill="none" height={18} width={18}>
      <circle cx={9} cy={9} fill="#E3E3E3" r={9} />
      <path
        d="M5.143 9.309l2.262 2.262 5.143-5.142"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default UncheckIcon;
