import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const usePermitListsApproverHooks = ({ manual = true, token }) => {
  const [{ data, loading, error }, executePermitListsApprover] = useHooks(
    {
      url: `${endpoint}/api/approver/permitlogs`,
      headers: { Authorization: `Bearer ${token}` },
    },
    { manual, useCache: false }
  );

  return { data, loading, error, executePermitListsApprover };
};

export default usePermitListsApproverHooks;
