import * as React from 'react';

const FourthPermitIcon = () => {
  return (
    <svg fill="none" height={67} width={76}>
      <path
        d="M19.277 2.243v5.773c.937-.312 3.723-.75 5.223 0s4.594 3.594 6 5L1 42.076l6.976 6.964L37.5 19.516l2.865 2.878-.937.937 6.23 6.026 9.842-9.84-5.294-5.09-1.406 1.406C45.207 11.928 36.241 3.274 32.867 1.774c-3.374-1.5-10.154-.468-13.59.469z"
        fill="#FFF500"
      />
      <path
        d="M46.17 30.857l10.28-10.402a1.139 1.139 0 000-1.582l-5.597-5.596c-.182-.243-.486-.304-.79-.304-.305 0-.609.121-.791.365l-.79.669-8.943-8.76C32.848-1.383 27.434-.41 19.161 1.111c-.365.06-.608.243-.79.547-.974 1.825-.913 4.502.243 6.692.304.547.912.851 1.52.79a18.29 18.29 0 012.373-.182c2.19 0 3.345.79 6.083 3.406a.658.658 0 01.182.426c0 .183-.06.304-.182.426L.426 41.502C.182 41.745 0 42.17 0 42.536s.182.79.426 1.095l5.84 5.84c.669.669 1.885.669 2.554 0l28.103-28.104a.588.588 0 01.852 0l1.217 1.156a.657.657 0 01.182.426c0 .121-.06.304-.182.426-.548.547-.548 1.52 0 2.068l5.535 5.414a1.11 1.11 0 001.338.182c.122 0 .244-.06.305-.182zm-1.278-2.799l-3.71-3.588.669-.67c.182-.243.304-.486.304-.79 0-.305-.122-.609-.365-.791l-3.528-3.407c-.548-.547-1.46-.547-2.068 0l-.609.67L8.03 47.097a.657.657 0 01-.425.183.552.552 0 01-.426-.183L3.04 43.023a.661.661 0 010-.913l28.53-28.529c.182-.243.304-.487.304-.79 0-.305-.122-.61-.365-.792-4.623-4.44-6.022-5.596-10.585-5.17-.304 0-.608-.183-.669-.426-.304-.912-.365-1.886-.182-2.737a.66.66 0 01.486-.487c8.395-1.52 12.105-1.581 17.398 3.65l9.732 9.55c.244.183.487.304.791.304.304 0 .608-.121.79-.365l.73-.73 3.529 3.528a.662.662 0 010 .913l-7.847 7.908c-.122.365-.547.365-.79.121z"
        fill="#096BEF"
      />
      <path
        d="M74.699 20.88a1.2 1.2 0 00.365-.851 1.2 1.2 0 00-.365-.852L66.122 10.6a1.176 1.176 0 00-1.704 0l-3.284 3.285a7.272 7.272 0 00-1.095 8.881l.304.487-21.534 21.533-5.11-5.11c-.486-.486-1.094-.73-1.703-.73-.669 0-1.277.244-1.703.73l-6.934 6.875c-3.346 3.345-4.806 8.15-3.772 12.835l.73 3.467c.243 1.034 1.034 1.886 2.13 2.129l3.466.73c4.684.973 9.49-.426 12.835-3.772l6.874-6.873c.487-.487.73-1.095.73-1.703 0-.67-.243-1.278-.73-1.704l-5.11-5.11 21.473-21.594.487.304a7.273 7.273 0 008.881-1.095l3.346-3.284zm-30.78 32.423l-6.874 6.934a11.656 11.656 0 01-8.212 3.407c-.79 0-1.642-.061-2.433-.244l-3.71-.79-.791-3.711c-.852-3.832.365-7.847 3.163-10.645l6.873-6.874 5.11 5.11-4.319 4.319a1.176 1.176 0 000 1.703c.426.426 1.278.426 1.703 0l4.32-4.319 5.17 5.11zm22.324-29.381c-1.277 0-2.554-.487-3.467-1.399-.912-.913-1.399-2.13-1.399-3.467 0-1.278.487-2.555 1.399-3.468l2.433-2.433 6.874 6.874-2.433 2.433a4.635 4.635 0 01-3.407 1.46z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default FourthPermitIcon;
