import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useDeleteFilesHooks = () => {
  const [{ data, loading, error }, executeDeleteFiles] = useHooks(
    {
      url: `${endpoint}/api/docs/remove`,
      method: 'PUT',
    },
    { manual: true }
  );

  return { data, loading, error, executeDeleteFiles };
};

export default useDeleteFilesHooks;
