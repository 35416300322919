import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useDownloadDocsHooks = ({ manual = true, token }) => {
  const [{ data, loading, error }, executeDownloadDocs] = useHooks(
    {
      url: `${endpoint}/api/docs/download`,
      headers: { Authorization: `Bearer ${token}` },
    },
    { manual, useCache: false }
  );

  return { data, loading, error, executeDownloadDocs };
};

export default useDownloadDocsHooks;
