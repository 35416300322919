import * as React from 'react';

const UncheckedRadioIcon = () => {
  return (
    <svg fill="none" height={20} width={20}>
      <circle cx={10} cy={10} fill="#fff" r={9.5} stroke="#096BEF" />
    </svg>
  );
};

export default UncheckedRadioIcon;
