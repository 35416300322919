import * as React from 'react';

const UploadIcon = () => {
  return (
    <svg fill="none" height={42} width={48}>
      <path
        d="M38.765 12.085C37.144 3.951 29.235-1.329 21.1.293a15.018 15.018 0 00-11.79 11.783C3.542 12.741-.595 17.955.07 23.723a10.513 10.513 0 0010.45 9.309h27.033C43.359 32.996 48.036 28.26 48 22.454a10.513 10.513 0 00-9.235-10.369z"
        fill="#FFF500"
      />
      <path
        d="M38.765 12.085C37.144 3.951 29.235-1.329 21.1.293a15.018 15.018 0 00-11.79 11.783C3.542 12.741-.595 17.955.07 23.723a10.513 10.513 0 0010.45 9.309h7.51a1.502 1.502 0 100-3.004h-7.51a7.51 7.51 0 11.09-15.017c.765 0 1.408-.576 1.492-1.337.763-6.591 6.724-11.316 13.316-10.554A12.014 12.014 0 0135.97 13.674a1.592 1.592 0 001.582 1.337 7.509 7.509 0 110 15.017h-7.51a1.502 1.502 0 000 3.004h7.51C43.359 32.996 48.036 28.26 48 22.454a10.513 10.513 0 00-9.235-10.369z"
        fill="#096BEF"
      />
      <path
        d="M18.726 24.497l3.807-3.808V39.59a1.502 1.502 0 103.004 0V20.689l3.807 3.808a1.501 1.501 0 102.123-2.123l-6.369-6.37a1.505 1.505 0 00-2.126 0l-6.37 6.37a1.502 1.502 0 102.124 2.123z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default UploadIcon;
