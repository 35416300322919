import { useSetRecoilState, useRecoilState } from 'recoil';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { deleteItemModal, uploadFilesModal } from 'common/data/modalState';
import CheckIcon from 'svg/Flow/Requirements/CheckIcon';
import DeleteFileIcon from 'svg/Flow/Requirements/DeleteFileIcon';
import DeleteModal from 'common/components/DeleteModal';
import ExpandIcon from 'svg/Flow/Requirements/ExpandIcon';
import formState from 'common/data/formState';
import Loading from 'common/components/Loading';
import PdfIcon from 'svg/Flow/Requirements/PdfIcon';
import UncheckIcon from 'svg/Flow/Requirements/UncheckIcon';
import UploadFilesModal from 'common/components/UploadModal';

import {
  useDeleteFilesHooks,
  useRequirementsHooks,
  useUploadFilesHooks,
} from 'services';

const Requirements = ({ isLoading, setLoading, setSubmit }) => {
  const [values, setValues] = useRecoilState(formState);
  const { accessToken, permitApplicationLogId, activeSubStep } = values;
  const setOpenDeleteModal = useSetRecoilState(deleteItemModal);
  const setOpenFilesModal = useSetRecoilState(uploadFilesModal);

  const { executeDeleteFiles } = useDeleteFilesHooks();
  const { error: errorUploadFiles, executeUploadFiles } = useUploadFilesHooks();
  const {
    data: requirementsGroups,
    loading: isLoadingRequirements,
    error: errorRequirements,
    executeRequirements,
  } = useRequirementsHooks({
    manual: !accessToken,
    permitId: permitApplicationLogId,
    subStepId: activeSubStep,
    token: accessToken,
  });

  const onDeleteFile = async id => {
    try {
      setLoading(true);

      const deleteFile = await executeDeleteFiles({
        params: { submittedDocumentId: id },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (deleteFile.status === 204) {
        const requirementsData = await executeRequirements({
          params: {
            permitApplicationStepId: activeSubStep,
            permitApplicationLogId: permitApplicationLogId,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (requirementsData?.status === 200) {
          setValues(state => ({
            ...state,
            deleteId: null,
            deleteName: null,
          }));
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      setValues(state => ({
        ...state,
        deleteId: null,
        deleteName: null,
      }));
      setLoading(false);
    }
  };

  const reqDocs = (requirementsGroups || []).map(
    ({ minRequiredDocs, permitApplicationDocuments }) => {
      const docs = (permitApplicationDocuments || []).filter(
        ({ submittedDocuments }) => submittedDocuments.length > 0
      );

      if (docs.length >= minRequiredDocs) {
        return true;
      }

      return false;
    }
  );

  useEffect(() => {
    if (reqDocs.length > 0) {
      const shouldSubmit = reqDocs.every(submit => Boolean(submit));

      setSubmit(shouldSubmit);
    }
  });

  if (isLoading || isLoadingRequirements) {
    return (
      <Box mt={10}>
        <Loading />
      </Box>
    );
  }
  if (errorRequirements) return <p>Error!</p>;
  if (!requirementsGroups) return null;

  return (
    <Box>
      {(requirementsGroups || []).map(
        (
          {
            label,
            name,
            note,
            permitRequirementsChecklistId,
            permitApplicationDocuments,
          },
          index
        ) => (
          <Box
            display="flex"
            flexDirection="column"
            key={permitRequirementsChecklistId}
            pb={3}
          >
            <Box>
              <Typography sx={{ fontWeight: 500 }} variant="body2">
                {String(index + 1).concat('. ', label || name, ';')}
              </Typography>
            </Box>

            {note && (
              <Box color="#9e9e9e" pl={2}>
                <Typography variant="caption">{note}</Typography>
              </Box>
            )}

            <Box display="flex" flexDirection="column" pl={2}>
              {(permitApplicationDocuments || []).map(
                ({
                  isReadonlyForApplicant,
                  label,
                  permitApplicationDocumentId,
                  submittedDocuments,
                }) => (
                  <Accordion
                    classes={{ root: 'Requirements_accordion' }}
                    disableGutters
                    key={permitApplicationDocumentId}
                  >
                    <AccordionSummary
                      classes={{ root: 'Requirements_summary' }}
                      expandIcon={<ExpandIcon />}
                    >
                      <Box display="flex" flexDirection="row">
                        <Box>
                          {(submittedDocuments || []).length === 0 ? (
                            <UncheckIcon />
                          ) : (
                            <CheckIcon />
                          )}
                        </Box>
                        <Box pl={1}>
                          <Typography variant="body2">{label}</Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      classes={{ root: 'Requirements_accordionDetails' }}
                    >
                      <Box display="flex" flexDirection="column">
                        {(submittedDocuments || []).length > 0 && (
                          <Box display="flex" flexDirection="column" py={2}>
                            {(submittedDocuments || []).map(
                              ({ fileName, submittedDocumentId, url }) => {
                                const isXls =
                                  url.match(/\.(xls|xlsx)$/) != null;
                                let urlLink = url;

                                if (isXls) {
                                  urlLink = `https://docs.google.com/gview?url=${encodeURIComponent(
                                    urlLink
                                  )}&embedded=true`;
                                }

                                return (
                                  <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                    key={submittedDocumentId}
                                    px={2}
                                    py={1}
                                  >
                                    <Link
                                      className="Requirements_fileLink"
                                      href={urlLink}
                                      rel="noopener"
                                      target="_blank"
                                    >
                                      <PdfIcon />
                                      <Typography
                                        sx={{ pl: 1 }}
                                        variant="caption"
                                      >
                                        {fileName}
                                      </Typography>
                                    </Link>
                                    {!isReadonlyForApplicant && (
                                      <Box
                                        onClick={() => {
                                          setValues(state => ({
                                            ...state,
                                            deleteId: submittedDocumentId,
                                            deleteName: fileName,
                                          }));
                                          setOpenDeleteModal(true);
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                      >
                                        <DeleteFileIcon />
                                      </Box>
                                    )}
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        )}
                        {!isReadonlyForApplicant && (
                          <Box display="flex" justifyContent="flex-end" py={1}>
                            <Typography
                              className="Requirements_upload"
                              onClick={() => {
                                setValues(state => ({
                                  ...state,
                                  permitApplicationDocumentId,
                                }));
                                setOpenFilesModal(true);
                              }}
                              variant="caption"
                            >
                              Upload Attachment
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
            </Box>
          </Box>
        )
      )}

      <DeleteModal onDelete={onDeleteFile} />
      <UploadFilesModal
        errorUpload={errorUploadFiles}
        executeRequirements={executeRequirements}
        executeUpload={executeUploadFiles}
      />
    </Box>
  );
};

Requirements.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSubmit: PropTypes.func.isRequired,
};

export default Requirements;
