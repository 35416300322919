import { atom, selector } from 'recoil';

const formState = atom({
  key: 'FormValues',
  default: selector({
    key: 'FormValues/Default',
    get: () => {
      return {
        accessToken: null,
        activeStep: null,
        activeSubStep: null,
        activeTab: null,
        deleteId: null,
        deleteName: null,
        parentUrl: null,
        permits: [],
        permitApplicationLogId: null,
        permitApplicationDocumentId: null,
        permitApplicationStatus: null,
        permitSteps: [],
        permitSubSteps: [],
        permitType: null,
        user: null,
        userRole: [],
        userType: 'visitor',

        // admin
        applicantStepLogId: null,
        approverStepLogId: null,
        businessName: null,
        currentStep: null,
        isLastStep: false,
        permitCategory: null,
        status: null,
        statusDisplay: null,
      };
    },
  }),
});

export default formState;
