import { Box, Container, Typography } from '@mui/material';

import * as styles from './Gcash.styles';
import t from 'common/utils/translations';
import u from 'common/utils/urls';

import Breadcrumb from 'common/components/Breadcrumb';
import Footer from 'common/components/Footer';

export const Gcash = () => {
  return (
    <Container disableGutters maxWidth={false}>
      <Box sx={styles.container}>
        <Breadcrumb parent="Home" step="GCash" subParent="Payment Options" />

        <Box sx={styles.title}>
          <Typography sx={styles.textFont} variant="h5">
            {t.payments.gcash.title}
          </Typography>
        </Box>

        <Container disableGutters maxWidth="lg">
          <Box sx={styles.box}>
            <Box sx={styles.leftBox}>
              <Box sx={styles.leftBoxField}>
                <Typography variant="body1">
                  {t.payments.gcash.description}
                </Typography>
              </Box>
              <Box sx={styles.leftBoxField}>
                <Typography sx={styles.textFont} variant="h6">
                  {t.payments.gcash.stepsPay}
                </Typography>
              </Box>
              <Box sx={styles.leftBoxField}>
                {t.payments.gcash.steps.map((step, index) => (
                  <Typography
                    key={`${index}-gcash-steps`}
                    sx={{ pb: 1 }}
                    variant="body1"
                  >
                    {step}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ ...styles.leftBoxField, display: 'flex' }}>
                <Typography sx={styles.textFont} variant="body1">
                  {t.payments.gcash.important}
                </Typography>
                <Typography pl={1} variant="body1">
                  {t.payments.gcash.keepCopy}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box
                alt="gcash payment"
                component="img"
                src={u.gcashImg}
                sx={styles.img}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Footer />
    </Container>
  );
};
