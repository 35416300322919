import * as React from 'react';

const PdfIcon = () => {
  return (
    <svg fill="none" height={18} width={17}>
      <path
        d="M2.586 17.456V.485h9.698l3.555 3.556v13.415H2.586z"
        fill="#FFF500"
      />
      <path
        d="M12.094 7.14c-.28 0-.628.046-1.047.116A9.451 9.451 0 019.49 4.93c.419-1.744.21-1.976.116-2.093-.093-.116-.232-.325-.395-.325-.07 0-.232.023-.302.046-.186.07-.28.186-.349.372-.21.512.07 1.372.395 2.047a19.232 19.232 0 01-1.162 3.302C6.629 8.814 6 9.35 5.954 9.837c-.023.186.024.442.35.698.092.07.185.093.301.093.28 0 .535-.21.86-.651.234-.326.49-.768.745-1.303a12.346 12.346 0 012.721-.86c.488.465.93.698 1.302.698.28 0 .512-.117.675-.372.186-.256.21-.466.116-.675-.163-.21-.465-.325-.93-.325zm-5.512 2.976c-.14-.116-.14-.186-.14-.209.024-.163.28-.465.954-.837-.511.907-.767 1.023-.814 1.046zm2.512-7.07c.023 0 .325.35.023 1-.419-.441-.046-.976-.023-1zM8.466 8c.302-.744.605-1.558.814-2.326a9.521 9.521 0 001.186 1.698c-.651.163-1.349.372-2 .628zm3.977-.163c-.093.14-.303.14-.372.14-.163 0-.233-.093-.489-.303.21-.023.396-.023.558-.023.28 0 .326.047.372.07 0 .023-.023.07-.07.116z"
        fill="#096BEF"
      />
      <path
        d="M15.978 3.767L12.397.186A.635.635 0 0011.955 0H3c-.395 0-.813.302-.813.953v16.373c0 .279.255.558.604.65h.047c.046 0 .116.024.162.024h12.35c.046 0 .092 0 .162-.023h.047c.348-.093.604-.372.604-.651V4.372c0-.256-.023-.442-.186-.605zm-3.511-.07V1.303l2.418 2.396h-2.418zm2.976 13.489c0 .023-.023.07-.046.07H3h-.046c-.023-.023-.047-.047-.047-.07V.953c0-.07.024-.232.093-.232h8.745v3.698H15.443v12.767z"
        fill="#096BEF"
      />
      <path d="M9.676 12.093H.652v3.535h9.024v-3.535z" fill="#fff" />
      <path
        d="M9.605 16.28H.698A.7.7 0 010 15.58v-3.418a.7.7 0 01.698-.698h8.907a.7.7 0 01.697.698v3.418c.024.373-.302.698-.697.698zM.698 12.092a.05.05 0 00-.047.046v3.442a.05.05 0 00.047.047h8.907a.05.05 0 00.046-.047v-3.418a.05.05 0 00-.046-.047H.698v-.023z"
        fill="#096BEF"
      />
      <path
        d="M3.51 12.907a.553.553 0 00-.256-.14 1.165 1.165 0 00-.279-.046h-.674V15h.372v-.814h.279c.116 0 .232-.023.326-.046.093-.047.186-.094.255-.14.07-.07.117-.14.163-.233a.743.743 0 000-.627c-.046-.094-.116-.163-.186-.233zm-.14.744c-.023.07-.046.116-.092.14-.047.046-.07.07-.117.07-.046.023-.093.023-.14.023h-.348v-.907h.279c.093 0 .163.023.232.046.047.023.094.07.14.117l.07.139c0 .046.023.093.023.14-.023.093-.023.186-.046.232zM5.769 13.046a.851.851 0 00-.372-.232c-.14-.07-.326-.093-.512-.093h-.697V15h1c.07 0 .116-.023.209-.047.07-.023.14-.07.232-.116a.886.886 0 00.21-.21c.07-.092.116-.209.162-.325.047-.116.07-.302.07-.488 0-.14-.023-.28-.07-.396a2.01 2.01 0 00-.232-.372zm-.28 1.442c-.115.163-.301.233-.534.233h-.372V13h.21c.185 0 .325.023.441.07.116.046.21.116.256.186.046.07.116.162.14.256.023.093.023.186.023.279 0 .302-.047.534-.163.697zM6.582 15h.372v-1.023h.977v-.256h-.977V13h1.07v-.28H6.582V15z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default PdfIcon;
