import { Box } from '@mui/material';
import { node } from 'prop-types';
import { useEffect, useRef } from 'react';

const ScrollContainer = ({ children }) => {
  const outerDiv = useRef(null);
  const innerDiv = useRef(null);

  const prevInnerDivHeight = useRef(null);

  useEffect(() => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight;
    const outerDivScrollTop = outerDiv.current.scrollTop;

    if (
      !prevInnerDivHeight.current ||
      outerDivScrollTop <= prevInnerDivHeight.current - outerDivHeight ||
      outerDivScrollTop >= prevInnerDivHeight.current - outerDivHeight
    ) {
      outerDiv.current.scrollTo({
        top: innerDivHeight - outerDivHeight + 16,
        left: 0,
        behavior: prevInnerDivHeight.current ? 'smooth' : 'auto',
      });
    }

    prevInnerDivHeight.current = innerDivHeight;
  }, [children]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        ref={outerDiv}
        sx={{
          position: 'relative',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Box
          ref={innerDiv}
          sx={{
            margin: '0px 8px',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

ScrollContainer.propTypes = {
  children: node.isRequired,
};

export default ScrollContainer;
