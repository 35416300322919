export const box = {
  background: '#ffffff',
  display: 'flex',
  // justifyContent: 'space-between',
  gap: 2,
  padding: '2.5rem',
};

export const container = {
  background: '#f9fbff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  pb: 8,
  pt: 2,
  px: 8,
};

export const img = {
  height: 440,
  width: 331,
};

export const leftBox = {
  display: 'flex',
  flexDirection: 'column',
};

export const leftBoxField = {
  py: '1rem',
};

export const title = {
  color: '#096BEF',
  display: 'flex',
  justifyContent: 'center',
  my: 5,
};

export const textFont = {
  color: '#096BEF',
};
