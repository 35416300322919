import * as React from 'react';

const ThirteenthPermitIcon = () => {
  return (
    <svg fill="none" height={55} width={56} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#thirteenth)">
        <path
          d="M30.004 1h-28.5v54h43S35 51.998 35 43.998s9.504-10 9.504-10V15.5h-14.5V1Z"
          fill="#FFF500"
        />
        <path
          d="M8.988 24.875a.728.728 0 0 1-.735-.697v-7.965a6.37 6.37 0 0 1-1.977-4.612c0-3.527 2.878-6.405 6.405-6.405s6.405 2.878 6.405 6.405v.031l-.012.006a6.37 6.37 0 0 1-1.976 4.581v3.776h.012v1.463h-.012v2.694a.729.729 0 0 1-1.045.653l-3.378-1.636-3.377 1.636a.742.742 0 0 1-.31.07Zm0 0v-.05.05Zm6.999-9.601.007-.004a4.917 4.917 0 0 0 1.64-3.675 4.929 4.929 0 0 0-4.941-4.942 4.932 4.932 0 0 0-4.942 4.942c0 1.496.66 2.827 1.706 3.733l.002.002.008.008.003.003a4.928 4.928 0 0 0 3.217 1.19c.152 0 .298-.006.445-.017.135-.011.265-.032.402-.054l2.453-1.186Zm0 0v.002-.002h-.002l-.004.003a.162.162 0 0 0-.028.02.326.326 0 0 1-.025.024h-.001a4.882 4.882 0 0 1-2.363 1.134l2.423-1.181Zm-3.623 6.42-2.642 1.28v-5.702a6.376 6.376 0 0 0 2.96.728 6.414 6.414 0 0 0 2.964-.728v5.702l-2.642-1.28a.752.752 0 0 0-.64 0Zm3.638-6.39.007.014-.007-.015ZM8.148 31.272h28.665a.834.834 0 1 0 0-1.67H8.148a.834.834 0 1 0 0 1.67ZM8.148 39.155h20.404a.834.834 0 1 0 0-1.67H8.148a.834.834 0 1 0 0 1.67ZM8.148 47.041h16.864a.834.834 0 1 0 0-1.67H8.148a.834.834 0 1 0 0 1.67Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeWidth={0.1}
        />
        <path
          d="M44.962 33.402c-5.954 0-10.798 4.845-10.798 10.798C34.164 50.154 39.008 55 44.962 55S55.76 50.154 55.76 44.2c0-5.953-4.844-10.798-10.798-10.798Zm0 19.927c-5.033 0-9.128-4.095-9.128-9.129 0-5.033 4.095-9.128 9.128-9.128s9.129 4.095 9.129 9.128c0 5.034-4.096 9.129-9.129 9.129Z"
          fill="#096BEF"
        />
        <path
          d="M39.045 53.224H1.776V1.776h26.748v13.33c0 .53.354.89.89.89h13.861v17.555a10.62 10.62 0 0 1 1.688-.147V15.106c0-.266-.177-.531-.354-.708L30.034.266a.956.956 0 0 0-.62-.266H.892C.354 0 0 .354 0 .891v53.224c0 .531.354.89.891.89h43.187c.07 0 .13-.023.195-.035a10.771 10.771 0 0 1-5.228-1.74v-.006ZM30.3 3.02l11.642 11.194H30.3V3.02Z"
          fill="#096BEF"
        />
        <path
          d="M39.535 48.249V48.1c.077-.17.2-.248.39-.242.336.006.613-.165.902-.3a1.633 1.633 0 0 0-.035-.095c-.342-.897-.684-1.794-1.02-2.69-.195-.514.058-1.004.59-1.145.2-.053.395-.107.595-.154.077-.017.106-.047.106-.136v-2.065c0-.53.36-.89.891-.89H43.371v-.13c0-.242-.006-.478 0-.72.017-.472.241-.82.66-1.033.13-.065.278-.089.42-.13h1.02c.018.006.042.018.06.018.524.076.926.454 1.002.98.042.288.018.59.024.89v.13h1.404c.065 0 .13 0 .19.012.424.07.707.413.713.85v2.1c0 .083.024.118.106.136.2.047.396.1.596.153.549.148.791.632.59 1.163-.265.708-.537 1.41-.802 2.118l-.248.65c.313.147.602.312.956.306.206 0 .348.136.342.325 0 .188-.16.312-.372.306a2.172 2.172 0 0 1-.955-.247c-.467-.242-.933-.236-1.399 0-.66.336-1.328.342-1.994 0-.484-.248-.956-.236-1.44.005-.643.33-1.298.33-1.941 0-.496-.253-.986-.26-1.481 0a1.939 1.939 0 0 1-.85.225c-.242.011-.325-.036-.425-.248l-.012.006Zm8.916-.803s.024-.047.03-.065l1.08-2.838c.058-.16 0-.254-.166-.3-1.404-.367-2.815-.732-4.219-1.104a.764.764 0 0 0-.43 0c-1.405.372-2.81.737-4.22 1.103-.177.047-.23.142-.165.307.36.938.714 1.877 1.074 2.82.024.06.053.077.112.077.348-.011.679.077.986.236.118.06.242.118.371.148.384.088.75.035 1.098-.16.26-.141.543-.212.844-.224.43-.023.826.095 1.21.29.389.194.796.194 1.185.011.384-.183.773-.33 1.204-.295l.006-.006Zm-6.75-4.171c.047-.012.082-.018.112-.03.997-.26 2-.525 3.003-.779a.649.649 0 0 1 .301 0c.79.2 1.588.413 2.378.62.242.065.484.124.738.194V41.298c0-.213-.065-.278-.283-.278h-5.972c-.212 0-.277.065-.277.278v1.977Zm4.219-2.898v-.808a.542.542 0 0 0-.543-.56 19.28 19.28 0 0 0-.826 0c-.272.005-.508.188-.531.442-.024.3-.006.614-.006.92h1.906v.006Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeMiterlimit={10}
          strokeWidth={0.8}
        />
      </g>
      <defs>
        <clipPath id="thirteenth">
          <path d="M0 0h55.761v55H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThirteenthPermitIcon;
