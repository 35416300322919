import * as React from 'react';

const UncheckedRadioIcon = () => {
  return (
    <svg fill="none" height={20} width={20}>
      <circle cx={10} cy={10} fill="#fff" r={9.5} stroke="#096BEF" />
      <path
        d="M5.714 10.34l2.515 2.515 5.714-5.714"
        stroke="#096BEF"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default UncheckedRadioIcon;
