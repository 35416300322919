import * as React from 'react';

const DeleteFileIcon = () => {
  return (
    <svg fill="none" height={20} width={17}>
      <path
        d="M14.244 5.448H1.911c-.706 0-1.286-.58-1.286-1.287 0-.706.58-1.286 1.286-1.286h12.333c.706 0 1.286.58 1.286 1.286 0 .707-.58 1.287-1.286 1.287z"
        fill="#FFF500"
      />
      <path
        d="M11.343 2.875H4.887V1.791c0-.681.555-1.21 1.21-1.21h4.01c.681 0 1.211.554 1.211 1.21v1.084h.025z"
        fill="#fff"
      />
      <path
        d="M12.403 19.52H3.55a1.808 1.808 0 01-1.816-1.815V5.448h12.51v12.257c0 1.009-.832 1.816-1.841 1.816z"
        fill="#FFF500"
      />
      <path
        d="M1.33 5.952v11.526c0 .68.251 1.311.68 1.79A2.311 2.311 0 003.675 20h8.852c.63 0 1.236-.252 1.665-.731.429-.454.68-1.11.68-1.791V5.952a1.782 1.782 0 001.312-1.967c-.126-.883-.883-1.564-1.765-1.564h-2.396v-.58c0-.504-.202-.958-.53-1.311A1.87 1.87 0 0010.182 0H6.02c-.504 0-.958.202-1.311.53-.353.353-.555.832-.53 1.311v.58H1.783c-.908 0-1.664.656-1.765 1.564a1.76 1.76 0 001.311 1.967zm11.197 13.09H3.675c-.807 0-1.413-.681-1.413-1.564V6.003h11.703v11.5c0 .858-.63 1.539-1.438 1.539zm-7.415-17.2c0-.253.101-.48.278-.657a.804.804 0 01.656-.252h4.161c.252 0 .48.101.656.252a.916.916 0 01.277.656v.58H5.112v-.58zM1.783 3.38h12.661a.84.84 0 01.832.832.84.84 0 01-.832.832H1.784a.84.84 0 01-.833-.832c-.025-.454.353-.832.832-.832z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default DeleteFileIcon;
