import * as React from 'react';

const ChatIcon = () => {
  return (
    <svg fill="none" height={80} width={80} xmlns="http://www.w3.org/2000/svg">
      <rect fill="url(#chat)" height={80} rx={40} width={80} />
      <path
        d="m36.126 28.736-19.9 19.19c-.81.78.088 2.097 1.11 1.628l15.42-7.067a1 1 0 0 1 1.062.146l10.355 8.761a1 1 0 0 0 1.366-.07l17.103-17.76c.813-.844-.24-2.157-1.24-1.547l-13.12 7.985a1 1 0 0 1-1.28-.203L37.58 28.805a1 1 0 0 0-1.453-.069Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="chat"
          x1={84.5}
          x2={12}
          y1={4}
          y2={70.5}
        >
          <stop stopColor="#FFF500" />
          <stop offset={1} stopColor="#FF8A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChatIcon;
