import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import Typography from '@mui/material/Typography';

import CheckedRadioIcon from 'svg/Review/CheckedRadioIcon';
import UncheckedRadioIcon from 'svg/Review/UncheckedRadioIcon';

const RadioGroupField = ({
  disabled = false,
  id,
  label,
  onChange = null,
  options,
  value = '',
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box color="#000000">
        <Typography variant="caption">
          <strong>{label}</strong>
        </Typography>
      </Box>
      <RadioGroup name={id} onChange={onChange} row value={value}>
        {options.map(({ label, value: radioValue }, index) => (
          <FormControlLabel
            control={
              <Radio
                checkedIcon={<CheckedRadioIcon />}
                color="primary"
                icon={<UncheckedRadioIcon />}
              />
            }
            disabled={disabled}
            key={index}
            label={<Typography variant="body2">{label}</Typography>}
            sx={{ pr: 3 }}
            value={radioValue}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

RadioGroupField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
};

export default RadioGroupField;
