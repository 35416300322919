import { useState } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close as CloseIcon,
} from '@mui/icons-material';
import { array, bool, func, string } from 'prop-types';

import { GoogleDocsViewer } from '../GoogleDocsViewer/GoogleDocsViewer';

const DisplayDoc = ({ doc }) => {
  const isImageUrl = doc.match(/\.(jpeg|jpg|gif|png)$/) != null;
  const isPdf = doc.match(/\.(pdf)$/) != null;
  const isXls = doc.match(/\.(xls|xlsx)$/) != null;

  if (isImageUrl) {
    return (
      <img
        alt={`Slide`}
        src={doc}
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          objectFit: 'cover',
          borderRadius: 8,
        }}
      />
    );
  } else if (isPdf || isXls) {
    return <GoogleDocsViewer docUrl={doc} />;
  }

  return null;
};

DisplayDoc.propTypes = {
  doc: string,
};

export const CarouselModal = ({ docs, open, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === docs?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? docs?.length - 1 : prevIndex - 1
    );
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: '90%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 2,
          outline: 0, // removes default outline of Modal
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
            zIndex: 9999,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '95%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          {docs?.length > 0 && docs[currentIndex] && (
            <DisplayDoc doc={docs[currentIndex]} />
          )}

          {/* Previous Button */}
          <IconButton
            onClick={prevSlide}
            sx={{
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
            }}
          >
            <ArrowBackIos />
          </IconButton>

          {/* Next Button */}
          <IconButton
            onClick={nextSlide}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>

        {/* Pagination Dots */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          {docs?.length > 0 &&
            docs.map((_, index) => (
              <Box
                key={index}
                onClick={() => setCurrentIndex(index)}
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: index === currentIndex ? 'black' : 'gray',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
              />
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

CarouselModal.propTypes = {
  docs: array,
  open: bool,
  onClose: func,
};
