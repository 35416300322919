import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const usePermitsActiveHooks = ({ manual = true }) => {
  const [{ data, loading, error }, executePermitsActive] = useHooks(
    {
      url: `${endpoint}/api/permits/active`,
      params: {
        page: 1,
        pageSize: 25,
      },
    },
    { manual, useCache: true }
  );

  return { data, loading, error, executePermitsActive };
};

export default usePermitsActiveHooks;
