import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { pages, userTypeValue } from 'common/utils/constants';
import { withPermissions } from 'common/hooks/withPermissions';
import DeleteModal from 'common/components/DeleteModal';
import Footer from 'common/components/Footer';
import formState from 'common/data/formState';
import Loading from 'common/components/Loading';
import PermitLists from './PermitLists';

import {
  useDeletePermitHooks,
  usePermitListsHooks,
  usePermitTypeHooks,
} from 'services';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const sortGroups = ['Draft', 'Pending', 'Approved', 'Rejected'];

const Permits = () => {
  const navigate = useNavigate();
  const [values, setValues] = useRecoilState(formState);
  const [isLoading, setLoading] = useState(false);
  const [permitListsDisplay, setPermitListsDisplay] = useState([]);
  const [sortType, setSortType] = useState('');

  const { accessToken } = values;

  const {
    data: permitListsGroup,
    loading: isLoadingPermitLists,
    error: errorPermitLists,
    executePermitLists,
  } = usePermitListsHooks({
    manual: !accessToken,
    token: accessToken,
  });

  const { executeDeletePermit } = useDeletePermitHooks();
  const { executePermitType } = usePermitTypeHooks();

  const onDeletePermit = async id => {
    try {
      setLoading(true);

      const deletePermit = await executeDeletePermit({
        url: `${endpoint}/api/applicants/permitlogs/${id}`,
        method: 'PUT',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (deletePermit.status === 204) {
        const permitsData = await executePermitLists({
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (permitsData?.status === 200 || permitsData?.status === 204) {
          setValues(state => ({
            ...state,
            deleteId: null,
            deleteName: null,
          }));
          setPermitListsDisplay(permitsData.data);
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      setValues(state => ({
        ...state,
        deleteId: null,
        deleteName: null,
      }));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (permitListsGroup) {
      setPermitListsDisplay(permitListsGroup);
    }
  }, [permitListsGroup, setPermitListsDisplay]);

  useEffect(() => {
    setValues(state => ({
      ...state,
      parentUrl: 'Permits',
    }));
  }, [setValues]);

  const onSelectPermit = async (id, permitApplicationLogId) => {
    try {
      setLoading(true);

      const permitTypeData = await executePermitType({
        params: {
          permitTypeId: id,
          permitApplicationLogId: permitApplicationLogId,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (permitTypeData?.status === 200) {
        const activeStep = permitTypeData?.data?.currentStep;

        setValues(state => ({
          ...state,
          activeSubStep: null,
          activeStep:
            permitTypeData?.data?.steps.findIndex(
              ({ permitApplicationStepId }) =>
                permitApplicationStepId === activeStep.permitApplicationStepId
            ) + 1,
          permitSteps: permitTypeData?.data,
          permitApplicationLogId: permitApplicationLogId,
        }));
        setLoading(false);
        navigate('/flow');
      }
    } catch (e) {
      console.error('Error fetching');
      setLoading(false);
    }
  };

  const onSortType = sort => {
    setLoading(true);
    setSortType(sort);

    const filterSortType = (permitListsGroup || []).filter(
      ({ status }) => status === sort
    );

    setPermitListsDisplay(filterSortType);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const onClearSort = () => {
    if (sortType) {
      setLoading(true);
      setSortType('');

      setPermitListsDisplay(permitListsGroup);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  if (isLoadingPermitLists || isLoading) {
    return (
      <Box height="40vh" paddingBottom="7px">
        <Loading />
      </Box>
    );
  }
  if (errorPermitLists) return <p>Error!</p>;

  return (
    <Box bgcolor="#f9fbff">
      <Container disableGutters maxWidth="lg">
        <Box display="flex" flexDirection="column" pb="233px" pt={5} px={8}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Box color="#010046">
                <Typography variant="h5">PERMITS</Typography>
              </Box>
              <Box color="#9e9e9e">
                <Typography variant="body1">Lists of your permits</Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Button
                className="PermitLists_delete"
                component="label"
                onClick={onClearSort}
                sx={{ bgcolor: '#ffffff', mr: 1, px: 3 }}
              >
                CLEAR
              </Button>
              <FormControl>
                <Select
                  classes={{ select: 'Permits_select' }}
                  displayEmpty
                  onChange={e => {
                    onSortType(e.target.value);
                  }}
                  renderValue={selected => {
                    if (!selected) {
                      return (
                        <Box color="#e3e3e3">
                          <Typography variant="body1">Sort by</Typography>
                        </Box>
                      );
                    }

                    return selected;
                  }}
                  sx={{ width: 160 }}
                  value={sortType}
                >
                  {sortGroups.map(sort => (
                    <MenuItem
                      className="Permits_menuItem"
                      key={sort}
                      value={sort}
                    >
                      {sort}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box marginTop="36px">
            {(permitListsDisplay || []).length === 0 && (
              <Box color="#9e9e9e" height="44vh" mt={1}>
                <Typography variant="body1">No Permits submitted.</Typography>
              </Box>
            )}
            {(permitListsDisplay || []).length > 0 && (
              <Grid container marginBottom="calc(100% - 79vh)" spacing={4}>
                {(permitListsDisplay || [])
                  .sort(
                    (a, b) =>
                      b.permitApplicationLogId - a.permitApplicationLogId
                  )
                  .map(
                    ({
                      applicationStartDate,
                      permitApplicationLogId,
                      permitType,
                      status,
                      submittedDocumentsCount,
                    }) => (
                      <Grid item key={permitApplicationLogId} xs={12}>
                        <PermitLists
                          applicationStartDate={applicationStartDate}
                          onSelectPermit={onSelectPermit}
                          permitApplicationLogId={permitApplicationLogId}
                          permitType={permitType}
                          status={status}
                          submittedDocumentsCount={submittedDocumentsCount}
                        />
                      </Grid>
                    )
                  )}
              </Grid>
            )}
          </Box>
        </Box>
      </Container>

      <DeleteModal onDelete={onDeletePermit} />
      <Footer />
    </Box>
  );
};

export default withPermissions(Permits, userTypeValue.APPLICANT, pages.PERMITS);
