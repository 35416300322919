import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const usePermitsActiveSearchHooks = ({ manual = true }) => {
  const [{ data, loading, error }, executePermitsSearchActive] = useHooks(
    {
      url: `${endpoint}/api/permits/search-active`,
    },
    { manual, useCache: true }
  );

  return { data, loading, error, executePermitsSearchActive };
};

export default usePermitsActiveSearchHooks;
