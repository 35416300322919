import { useSetRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import './styles.scss';
import { pages, userTypeValue } from 'common/utils/constants';
import { usePermitsActiveHooks, usePermitsActiveSearchHooks } from 'services';
import { withPermissions } from 'common/hooks/withPermissions';
import Footer from 'common/components/Footer';
import Loading from 'common/components/Loading';
import PaginationPermits from './PaginationPermits';
import SearchIcon from 'svg/Dashboard/SearchIcon';
import TablePermits from './TablePermits';
import tableState from 'common/data/tableState';
import t from 'common/utils/translates';

const ActivePermits = () => {
  const [isLoading, setLoading] = useState(false);
  const [searchBusinessName, setBusinessName] = useState('');
  const setValues = useSetRecoilState(tableState);

  const {
    data: permitsActive,
    loading: isLoadingPermitsActive,
    error: errorPermitsActive,
    executePermitsActive,
  } = usePermitsActiveHooks({ manual: false });

  const { executePermitsSearchActive } = usePermitsActiveSearchHooks({
    manual: true,
  });

  useEffect(() => {
    if (permitsActive) {
      setValues(state => ({
        ...state,
        currentPage: permitsActive.currentPage,
        items: permitsActive.details,
        pageSize: permitsActive.pageSize,
        totalItems: permitsActive.totalItems,
        totalPages: permitsActive.totalPages,
      }));
    }
  }, [permitsActive, setValues]);

  const onSearch = async text => {
    try {
      setLoading(true);

      const response = await executePermitsSearchActive({
        params: {
          page: 1,
          pageSize: 25,
          searchText: text,
        },
      });

      if (response.status === 200) {
        setValues(state => ({
          ...state,
          currentPage: response?.data.currentPage,
          items: response?.data.details,
          pageSize: response?.data.pageSize,
          totalItems: response?.data.totalItems,
          totalPages: response?.data.totalPages,
        }));

        setLoading(false);
      } else {
        setValues(state => ({
          ...state,
          currentPage: 0,
          items: [],
          pageSize: 0,
          totalItems: 0,
          totalPages: 0,
        }));

        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onClearSearch = async () => {
    try {
      setBusinessName('');

      const response = await executePermitsActive({
        params: {
          page: 1,
          pageSize: 25,
        },
      });

      if (response.status === 200) {
        setValues(state => ({
          ...state,
          currentPage: response?.data.currentPage,
          items: response?.data.details,
          pageSize: response?.data.pageSize,
          totalItems: response?.data.totalItems,
          totalPages: response?.data.totalPages,
        }));

        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoadingPermitsActive) {
    return (
      <Box height="40vh" paddingBottom="7px">
        <Loading />
      </Box>
    );
  }

  if (errorPermitsActive) return <p>{t.errors.error}</p>;

  return (
    <Box bgcolor="#f9fbff">
      <Container disableGutters maxWidth="lg">
        <Box className="ActivePermits_container">
          <Box className="ActivePermits_header">
            <Box display="flex">
              <Box color="#010046">
                <Typography variant="h5">
                  {t.applicant.publicPermit.active}
                </Typography>
              </Box>
            </Box>

            <Box display="flex">
              <Button
                className="ActivePermits_button"
                component="label"
                onClick={onClearSearch}
                sx={{ bgcolor: '#ffffff', mr: 1, px: 3 }}
              >
                {t.applicant.publicPermit.clear}
              </Button>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e => {
                          if (searchBusinessName) {
                            onSearch(searchBusinessName);
                          }
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={e => setBusinessName(e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter' && searchBusinessName) {
                    onSearch(e.target.value);
                  }
                }}
                placeholder={t.applicant.publicPermit.search}
                value={searchBusinessName || ''}
                variant="outlined"
              />
            </Box>
          </Box>

          <TablePermits isLoading={isLoading} />

          <PaginationPermits
            searchBusinessName={searchBusinessName}
            setLoading={setLoading}
          />
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default withPermissions(
  ActivePermits,
  userTypeValue.VISITOR,
  pages.VISITOR
);
