import * as React from 'react';

const ExpandIcon = () => {
  return (
    <svg fill="none" height={11} width={12}>
      <path
        d="M5.567.75a.5.5 0 01.866 0l5.196 9a.5.5 0 01-.433.75H.804a.5.5 0 01-.433-.75l5.196-9z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default ExpandIcon;
