import { useRecoilState, useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { deleteItemModal } from 'common/data/modalState';
import formState from 'common/data/formState';

const DeleteModal = ({ onDelete }) => {
  const [isOpen, setOpen] = useRecoilState(deleteItemModal);
  const formValues = useRecoilValue(formState);

  const { deleteId, deleteName } = formValues;

  const handleClose = () => setOpen(false);

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="xs" open={isOpen}>
      <DialogTitle sx={{ pb: 0 }}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="Close" onClick={handleClose}>
            <Close color="primary" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent data-cy="delete-item">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          pb={2}
          px={3}
          textAlign="center"
        >
          <Box pb={2} sx={{ wordBreak: 'break-word' }}>
            <Typography variant="body1">
              {`Are you sure you want to delete ${deleteName}?`}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Button
              className="PermitLists_delete"
              component="label"
              onClick={handleClose}
              sx={{ px: 3 }}
            >
              <Typography variant="body2">Cancel</Typography>
            </Button>
            <Button
              component="label"
              onClick={() => {
                onDelete(deleteId);
                handleClose();
              }}
              sx={{
                background:
                  'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                boxShadow: 'none',
                ml: 2,
                px: 5,
              }}
              variant="contained"
            >
              <Typography variant="body2">Yes</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DeleteModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
