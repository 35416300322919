import * as React from 'react';

const FifteenthPermitIcon = () => {
  return (
    <svg fill="none" height={60} width={44} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#fifteenth)">
        <path
          d="M1.047 1.57h42.428v55h-6.81v-8.904c1.049-1.746 2.62-3.143 2.62-6.81s-4.19-6.81-7.333-6.81c-5.762 0-8.381 3.667-8.381 7.858 0 3.352 1.396 4.365 2.619 5.762v8.38H1.047V1.57Z"
          fill="#FFF500"
        />
        <path
          d="M7.077 11.378h29.84a.967.967 0 1 0 0-1.934H7.078a.967.967 0 1 0 0 1.934ZM7.077 19.585h29.84a.967.967 0 1 0 0-1.934H7.078a.967.967 0 1 0 0 1.934ZM7.077 27.792h29.84a.967.967 0 1 0 0-1.934H7.078a.967.967 0 1 0 0 1.934Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeWidth={0.3}
        />
        <path
          d="M16.32 48.988a1.94 1.94 0 0 1-.418-.049 1.772 1.772 0 0 1-1.308-1.222l-.233-.762-1.309 1.167a2.951 2.951 0 0 1-2.217.768 2.15 2.15 0 0 1-1.56-.835c-.873.325-1.77.559-2.69.694a.678.678 0 1 1-.179-1.345h.006a13.227 13.227 0 0 0 2.328-.602 3.106 3.106 0 0 1-.055-.676 4.272 4.272 0 0 1 1.892-3.243 1.503 1.503 0 0 1 2.082.411c.178.264.264.584.252.903-.055 1.517-1.087 2.55-2.297 3.243.11.062.233.093.356.105.436.024.866-.135 1.185-.436l1.315-1.161a1.36 1.36 0 0 1 1.917.129c.122.141.214.307.276.485l.233.756c.074.233.32.362.547.288a.472.472 0 0 0 .19-.116l1.192-1.26a1.734 1.734 0 0 1 2.445-.073c.313.295.504.7.54 1.13l.013.153a.68.68 0 0 1-1.358.105l-.012-.154a.364.364 0 0 0-.4-.344.372.372 0 0 0-.245.117l-1.192 1.26a1.767 1.767 0 0 1-1.296.564Zm-4.908-5.006s-.055.012-.08.025a2.902 2.902 0 0 0-1.29 2.187 2.638 2.638 0 0 0 1.511-2.058c0-.056-.024-.11-.073-.136a.124.124 0 0 0-.068-.018Z"
          fill="#096BEF"
        />
        <path
          d="M7.077 35.999H18.49a.967.967 0 1 0 0-1.934H7.077a.967.967 0 1 0 0 1.934Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeWidth={0.3}
        />
        <path
          d="M36.828 57.19h6.355c.53 0 .967-.428.967-.966V.817a.967.967 0 0 0-.967-.967H.817A.963.963 0 0 0-.15.817v55.407c0 .53.429.967.967.967h24.206v1.226a.92.92 0 0 0 .918.912h.006c.133 0 .265-.03.39-.088v-.001l4.591-2.223 4.591 2.223h.002a.913.913 0 0 0 1.219-.435l-.136-.063.224-1.551Zm-6.31-2.022-3.666 1.78v-7.936l.006.003a8.596 8.596 0 0 0 4.064 1.026 8.58 8.58 0 0 0 4.064-1.026l.006-.003v7.931l-3.672-1.775a.905.905 0 0 0-.801 0l.065.135-.065-.135ZM26.53 46.57a6.749 6.749 0 0 1-2.35-5.108c0-3.713 3.023-6.742 6.742-6.742a6.747 6.747 0 0 1 6.742 6.742c0 1.917-.823 3.75-2.253 5.03l-.02.017-.019.013-.002.001-.005.004-.017.011a6.776 6.776 0 0 1-3.23 1.558 5.353 5.353 0 0 1-.59.078 9.73 9.73 0 0 1-.606.024 6.707 6.707 0 0 1-4.392-1.628Zm-1.507 1.097v7.589H1.778V1.777h40.438v53.48h-5.388v-7.59l.002-.002c1.72-1.637 2.67-3.838 2.67-6.196 0-4.726-3.845-8.578-8.578-8.578-4.733 0-8.571 3.846-8.571 8.578 0 2.358.95 4.559 2.67 6.196h-.001l.003.003Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeWidth={0.3}
        />
      </g>
      <defs>
        <clipPath id="fifteenth">
          <path d="M0 0h44v59.172H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FifteenthPermitIcon;
