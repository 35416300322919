import useHooks from 'axios-hooks';

const useSubmitPermitHooks = () => {
  const [{ data, loading, error }, executeSubmitPermit] = useHooks({
    manual: true,
  });

  return { data, loading, error, executeSubmitPermit };
};

export default useSubmitPermitHooks;
