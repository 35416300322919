import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { pages, userTypeValue } from 'common/utils/constants';
import { withPermissions } from 'common/hooks/withPermissions';
import Breadcrumb from 'common/components/Breadcrumb';
import Footer from 'common/components/Footer';
import formState from 'common/data/formState';
import PermitDetails from './PermitDetails';

const Review = () => {
  const navigate = useNavigate();
  const formValues = useRecoilValue(formState);

  const { businessName, currentStep, parentUrl, permitCategory } = formValues;

  useEffect(() => {
    if (!currentStep) {
      navigate('/dashboard');
    }
  }, [navigate, currentStep]);

  if (!currentStep) return null;

  return (
    <Container disableGutters maxWidth={false}>
      <Box
        bgcolor="#f9fbff"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pb={8}
        pt={2}
        px={8}
      >
        <Breadcrumb
          parent={parentUrl}
          step={businessName}
          subParent={permitCategory}
        />

        <Box color="#010046" mt={5} pl="20%" textAlign="center">
          <Typography variant="h5">PERMITS</Typography>
        </Box>

        <Box color="#9e9e9e" mt={2} pl="20%" textAlign="center">
          <Typography variant="body1">{businessName}</Typography>
        </Box>

        <PermitDetails />
      </Box>

      <Footer />
    </Container>
  );
};

export default withPermissions(Review, userTypeValue.APPROVER, pages.REVIEW);
