import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useApproverActionHooks = () => {
  const [{ data, loading, error }, executeApproverAction] = useHooks(
    {
      url: `${endpoint}/api/approver/action`,
      method: 'PUT',
    },
    { manual: true }
  );

  return { data, loading, error, executeApproverAction };
};

export default useApproverActionHooks;
