import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const usePermitsHooks = ({ manual = true }) => {
  const [{ data, loading, error }, executePermits] = useHooks(
    { url: `${endpoint}/api/permits` },
    { manual, useCache: true }
  );

  return { data, loading, error, executePermits };
};

export default usePermitsHooks;
