import * as React from 'react';

const ActiveCheckIcon = () => {
  return (
    <svg fill="none" height={16} width={16}>
      <circle cx={8} cy={8} fill="#fff" r={8} />
      <path
        d="M4.571 8.274l2.012 2.012 4.571-4.572"
        stroke="#096BEF"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default ActiveCheckIcon;
