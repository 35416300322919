import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import React, { useEffect } from 'react';

import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import './styles.scss';
import { dayConverter } from 'common/utils/dateConvert';
import { usePermitsHooks, usePermitTypeHooks } from 'services';
import AnchorIcon from 'svg/Home/AnchorIcon';
import ChatBox from 'common/components/ChatBox';
import Footer from 'common/components/Footer';
import formState from 'common/data/formState';
import Loading from 'common/components/Loading';
import PermitCard from './PermitCard';

import t from 'common/utils/translates';

const Home = () => {
  const navigate = useNavigate();
  const [values, setValues] = useRecoilState(formState);
  const day = dayConverter();

  const { accessToken, user } = values;

  const {
    data: permitsGroups,
    loading: isLoadingPermits,
    error: errorPermits,
  } = usePermitsHooks({ manual: user ? !accessToken : Boolean(user) });

  const {
    loading: isLoadingPermitType,
    error: errorPermitType,
    executePermitType,
  } = usePermitTypeHooks();

  useEffect(() => {
    if (accessToken) {
      setValues(state => ({
        ...state,
        parentUrl: 'Home',
      }));
    }
  }, [accessToken, setValues]);

  const onSelectPermit = async id => {
    try {
      const permitTypeData = await executePermitType({
        params: {
          permitTypeId: id,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (permitTypeData?.status === 200) {
        setValues(state => ({
          ...state,
          activeSubStep: null,
          activeStep: 0,
          permitSteps: permitTypeData?.data,
          permitApplicationLogId: null,
        }));
        navigate('/flow');
      }
    } catch (e) {
      console.error('Error fetching');
    }
  };

  if (isLoadingPermits || isLoadingPermitType) return <Loading />;
  if (errorPermits || errorPermitType) return <p>{t.errors.error}</p>;
  if (!permitsGroups) return null;

  return (
    <Container disableGutters maxWidth={false}>
      <Box
        alignItems="center"
        bgcolor="#f9fbff"
        display="flex"
        flexDirection="column"
        px={8}
        py={5}
      >
        <Box color="#010046" mt={2} textAlign="center">
          <Typography variant="body1">{t.applicant.begin}</Typography>
        </Box>

        <Box display="flex" mt={4} width="100%">
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Card
                elevation={2}
                sx={{
                  background:
                    'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                }}
              >
                <CardContent>
                  <Box className="Home_header">
                    <Box mb={2}>
                      <AnchorIcon />
                    </Box>
                    <Box color="#ffffff">
                      {user && (
                        <Typography sx={{ marginBottom: 2 }} variant="body1">
                          {`${day} ${user?.given_name}!`}
                        </Typography>
                      )}
                      <Typography variant="body1">
                        {t.applicant.getHassle}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            {(permitsGroups || []).map(({ permitTypeId, name }) => (
              <Grid item key={permitTypeId} md={3} xs={12}>
                <PermitCard
                  id={String(permitTypeId)}
                  name={name}
                  onSelectPermit={onSelectPermit}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {user && <ChatBox />}
      <Footer />
    </Container>
  );
};

export default Home;
