import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { bool } from 'prop-types';
import { useRecoilValue } from 'recoil';
import React from 'react';

import ActiveLists from '../ActiveLists';
import tableState from 'common/data/tableState';

const TablePermits = ({ isLoading }) => {
  const tableValues = useRecoilValue(tableState);
  const items = tableValues?.items || [];

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" my={20}>
        <CircularProgress />
      </Box>
    );

  return (
    <Grid container marginBottom="40px" marginTop="40px" spacing={4}>
      <Grid item xs={12}>
        <Box
          alignItems="center"
          display="grid"
          gridTemplateColumns="15% 35% 30% 20%"
          px={4}
        >
          <Box color="#010046">
            <Typography sx={{ fontWeight: 400 }} variant="body2">
              EXPIRATION DATE
            </Typography>
          </Box>
          <Box color="#010046">
            <Typography sx={{ fontWeight: 400 }} variant="body2">
              PERMIT CATEGORY
            </Typography>
          </Box>
          <Box color="#010046">
            <Typography sx={{ fontWeight: 400 }} variant="body2">
              BUSINESS NAME
            </Typography>
          </Box>
          <Box color="#010046">
            <Typography sx={{ fontWeight: 400 }} variant="body2">
              CERTIFICATE
            </Typography>
          </Box>
        </Box>
      </Grid>
      {items.length === 0 && (
        <Box color="#9e9e9e" height="44vh" ml={8} mt={3}>
          <Typography variant="body1">No Permits submitted.</Typography>
        </Box>
      )}
      {items.length > 0 &&
        (items || [])
          .slice()
          .sort((a, b) => b.permitApplicationLogId - a.permitApplicationLogId)
          .map(data => (
            <Grid item key={data?.permitApplicationLogId} xs={12}>
              <ActiveLists data={data} />
            </Grid>
          ))}
    </Grid>
  );
};

TablePermits.propTypes = {
  isLoading: bool.isRequired,
};

export default TablePermits;
