import * as React from 'react';

const VerticalIcon = () => {
  return (
    <svg fill="none" height={20} width={1}>
      <path d="M.5 0v20" stroke="#E3E3E3" />
    </svg>
  );
};

export default VerticalIcon;
