import * as React from 'react';

const FifthPermitIcon = () => {
  return (
    <svg fill="none" height={57} width={56}>
      <path
        d="M1 55.5V1h30v13.5h14.5v8l-4.5 14 .5 11 4-3.5v11.5H1z"
        fill="#FFF500"
      />
      <path
        d="M8.05 16.453h13.162c.547 0 .911-.364.911-.91V6.216c0-.547-.364-.91-.91-.91H8.05c-.546 0-.91.363-.91.91v9.325c-.012.461.449.91.91.91zm.911-9.326h11.34v7.492H8.962V7.127zM6.217 22.62H34.01c.547 0 .911-.363.911-.91 0-.546-.364-.91-.91-.91H6.216c-.547 0-.91.364-.91.91 0 .547.363.91.91.91zM6.217 29.3H34.01c.547 0 .911-.365.911-.912 0-.546-.364-.91-.91-.91H6.216c-.547 0-.91.364-.91.91 0 .547.363.911.91.911zM6.217 35.965H34.01c.547 0 .911-.364.911-.91 0-.547-.364-.911-.91-.911H6.216c-.547 0-.91.364-.91.91 0 .547.363.911.91.911zM34.836 41.733c0-.546-.364-.91-.91-.91H6.215c-.546 0-.91.364-.91.91s.364.91.91.91H34.01c.462 0 .826-.46.826-.91z"
        fill="#096BEF"
      />
      <path
        d="M53.39 12.98l-1.458-.461c-.364-.098-.728-.183-1.19-.183-1.65 0-3.108 1.093-3.654 2.647l-.826 2.465v-1.906c0-.28-.182-.546-.364-.729L30.902.28A1.008 1.008 0 0030.259 0H.91C.364 0 0 .364 0 .91v54.762c0 .547.364.91.91.91h44.43c.545 0 .91-.363.91-.91V43.336l2.562-3.473.097-.28 6.86-21.855c.729-2.004-.364-4.104-2.38-4.748zM31.18 3.108l11.973 11.523H31.18V3.108zm13.345 51.654H1.833V1.822h27.515v13.72c0 .546.364.91.91.91h14.268v6.861L40.227 36.84v.279l.182 11.608c0 .364.28.728.644.826.364.097.826 0 1.008-.365l2.465-3.29v8.863zm-2.38-8.864l-.182-7.59 4.565 1.458-4.383 6.132zm11.972-28.705l-6.581 20.934-5.306-1.737 6.593-20.848c.364-1.008 1.457-1.651 2.465-1.275l1.457.462c1.105.267 1.748 1.457 1.372 2.464z"
        fill="#096BEF"
      />
      <path
        d="M32.25 52.892c-.17 0-.34-.025-.51-.061a2.16 2.16 0 01-1.615-1.518l-.291-.935-1.628 1.445a3.666 3.666 0 01-2.744.947 2.632 2.632 0 01-1.93-1.032c-1.081.4-2.198.692-3.327.862a.838.838 0 01-.947-.716.838.838 0 01.716-.947h.012a16.82 16.82 0 002.89-.741 3.89 3.89 0 01-.06-.838 5.31 5.31 0 012.343-4.019c.85-.57 2.003-.34 2.574.51.218.328.328.716.316 1.117-.073 1.882-1.348 3.157-2.842 4.02.134.072.292.12.45.133a2.04 2.04 0 001.469-.535l1.627-1.432a1.685 1.685 0 012.368.158c.157.17.267.376.34.607l.291.935c.085.291.389.449.68.364a.469.469 0 00.23-.146l1.47-1.554a2.148 2.148 0 013.035-.085c.389.364.632.862.668 1.396l.012.195a.842.842 0 01-1.675.133l-.012-.194a.455.455 0 00-.498-.425.453.453 0 00-.304.146l-1.469 1.554c-.45.4-1.032.656-1.64.656zm-6.071-6.205a.138.138 0 00-.098.036 3.556 3.556 0 00-1.59 2.708 3.274 3.274 0 001.87-2.55.194.194 0 00-.097-.17c-.037-.012-.061-.024-.085-.024z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default FifthPermitIcon;
