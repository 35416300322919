import * as React from 'react';

const ChatCloseIcon = () => {
  return (
    <svg fill="none" height={24} width={24}>
      <path
        clipRule="evenodd"
        d="m13.414 12 4.293-4.293a.999.999 0 1 0-1.414-1.414L12 10.586 7.707 6.293a.999.999 0 1 0-1.414 1.414L10.586 12l-4.293 4.293a.999.999 0 1 0 1.414 1.414L12 13.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L13.414 12Z"
        fill="#096BEF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ChatCloseIcon;
