import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useSubmitPermitHooks } from 'services';
import formState from 'common/data/formState';
import Requirements from '../Requirements';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const displayActiveStep = {
  1: {
    title: 'Application Pending',
  },
  2: {
    title: 'Application Pending',
  },
  3: {
    title: 'CONGRATULATIONS!',
  },
};

const TabsSection = ({ onTabChange, step }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const formValues = useRecoilValue(formState);
  const {
    accessToken,
    activeStep,
    activeSubStep,
    activeTab,
    permitApplicationLogId,
    permitApplicationStatus,
    permitType,
  } = formValues;

  const { executeSubmitPermit } = useSubmitPermitHooks();

  const reqStep = (step?.subSteps || []).find(
    ({ permitApplicationStepId }) => permitApplicationStepId === activeSubStep
  );

  const onSubmitPermit = async id => {
    try {
      setLoading(true);

      const submitPermit = await executeSubmitPermit({
        url: `${endpoint}/api/applicants/submit/${id}`,
        method: 'PUT',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (submitPermit.status === 204) {
        setLoading(false);
        navigate('/permits');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleChange = async (event, newValue) => {
    const category = newValue === 0 ? 'New Applicant' : 'Renewal';
    onTabChange(category);
  };

  const displayDetailsCongrats = () => {
    const defaultDetails =
      'Your permit application has been approved. Please visit the Cebu Port Authority Main Office to claim your approved permit/s. We will send you an SMS with the details of your orientation schedule.';
    const otherDetails =
      'Claim your ID(s) during office hours, 3 working days after payment. When claiming, surrender any expired ID(s) and bring your company ID.';

    if (permitType?.id === 17) {
      return otherDetails;
    }

    return defaultDetails;
  };

  const displayDetailsFooter = () => {
    const defaultDetails =
      'To obtain further details, please send an email to bmdd_staff01@cpa.gov.ph or dial (32) 402-4040 local 1090, and a representative will promptly reach out to you.';
    const otherDetails =
      'To obtain further details, please send an email to access@cpa.gov.ph or dial (32) 402-4040 local 2007, and a representative will promptly reach out to you.';

    if (permitType?.id === 16 || permitType?.id === 17) {
      return otherDetails;
    }

    return defaultDetails;
  };

  return (
    <Container disableGutters maxWidth="lg">
      <Box bgcolor="#ffffff" my={4} p={5}>
        {step?.categories && !permitApplicationLogId && (
          <Tabs onChange={handleChange} value={activeTab} variant="fullWidth">
            {(step?.categories || []).map(category => (
              <Tab key={category} label={category} sx={{ width: '100%' }} />
            ))}
          </Tabs>
        )}

        {activeStep === 0 && permitApplicationStatus === 'Draft' && (
          <Box
            bgcolor="#ffeeee"
            color="#ff0000"
            display="flex"
            justifyContent="center"
            mb={2}
            py={2}
          >
            <Typography sx={{ fontWeight: 500 }} variant="body2">
              Your Application is in draft. Click submit to continue.
            </Typography>
          </Box>
        )}

        {activeStep === 0 &&
          permitApplicationStatus === 'Pending' &&
          step?.completedStepStatusMessage && (
            <Box
              bgcolor="#f1fff8"
              color="#2bb673"
              display="flex"
              justifyContent="center"
              mb={2}
              py={2}
            >
              <Typography sx={{ fontWeight: 500 }} variant="body2">
                {step?.completedStepStatusMessage}
              </Typography>
            </Box>
          )}

        {(activeStep === 1 || activeStep === 2 || activeStep === 3) && (
          <Box
            color="#010046"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={2}
            width="100%"
          >
            <Box
              borderBottom="1px solid #e3e3e3"
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Typography
                sx={{
                  borderBottom: '2px solid #096bef',
                  fontWeight: 500,
                  pb: 2,
                  px: 20,
                }}
                variant={activeStep === 3 ? 'h5' : 'body2'}
              >
                {displayActiveStep[activeStep].title}
              </Typography>
            </Box>
            {step?.completedStepStatusMessage && (
              <Box py={2}>
                <Typography variant="body2">
                  {step?.completedStepStatusMessage}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Box display="flex" flexDirection="column">
          {reqStep?.description && (
            <Box color="#096bef" py={3}>
              <Typography
                sx={{
                  fontWeight: 500,
                  textTransform: 'uppercase',
                }}
                variant="body2"
              >
                {reqStep?.description}
              </Typography>
            </Box>
          )}

          {activeStep === 3 ? (
            <Box>
              <Typography variant="body1">
                {displayDetailsCongrats()}
              </Typography>
            </Box>
          ) : (
            <Requirements
              isLoading={isLoading}
              setLoading={setLoading}
              setSubmit={setSubmit}
            />
          )}
        </Box>

        {!isLoading && (
          <Fragment>
            <Box
              display="flex"
              justifyContent="center"
              m={5}
              textAlign="center"
            >
              <Typography variant="body1">{displayDetailsFooter()}</Typography>
            </Box>

            {permitApplicationLogId && isSubmit && activeStep < 3 && (
              <Box display="flex" justifyContent="center">
                <Button
                  component="label"
                  onClick={() => onSubmitPermit(permitApplicationLogId)}
                  sx={{
                    background:
                      'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                    boxShadow: 'none',
                    ml: 2,
                    px: 5,
                  }}
                  variant="contained"
                >
                  <Typography variant="body1">SUBMIT</Typography>
                </Button>
              </Box>
            )}
          </Fragment>
        )}
      </Box>
    </Container>
  );
};

TabsSection.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  step: PropTypes.object,
};

TabsSection.defaultProps = {
  step: undefined,
};

export default TabsSection;
