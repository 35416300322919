import { Routes, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import React from 'react';

import NavigationBar from 'common/components/NavigationBar';
import PageLayout from 'common/components/PageLayout';
import RedirectToFlow from 'common/components/RedirectToFlow';

// ADMIN components
import Dashboard from 'tenants/admin/Dashboard';
import Review from 'tenants/admin/Review';

// APPLICANT components
import Home from 'tenants/applicant/Home';
import Flow from 'tenants/applicant/Flow';
import Permits from 'tenants/applicant/Permits';

// GUEST components
// import { AnnualVehiclePass } from 'tenants/visitor/Forms/AnnualVehiclePass/AnnualVehiclePass';
import { Gcash } from 'tenants/visitor/Payments/Gcash/Gcash';
import ActivePermits from 'tenants/visitor/ActivePermits';

const App = () => {
  return (
    <PageLayout>
      <NavigationBar />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>

      <Routes>
        {/* applicant */}
        <Route element={<Home />} index />
        <Route element={<Flow />} path="/flow" />
        <Route element={<Permits />} path="/permits" />

        {/* approver */}
        <Route element={<Dashboard />} path="/dashboard" />
        <Route element={<Review />} path="/review" />

        {/* visitor */}
        <Route element={<ActivePermits />} path="/permits-list" />
        {/* <Route element={<AnnualVehiclePass />} path="/avp-form" /> */}
        <Route element={<Gcash />} path="/gcash-payment" />
        <Route element={<RedirectToFlow />} path="*" />
      </Routes>
    </PageLayout>
  );
};

export default App;
