import * as React from 'react';

const CheckIcon = () => {
  return (
    <svg fill="none" height={18} width={18}>
      <circle cx={9} cy={9} fill="#2BB673" r={9} />
      <path
        d="M5.145 9.309l2.262 2.262L12.55 6.43"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default CheckIcon;
