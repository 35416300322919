import * as React from 'react';

const AnchorIcon = () => {
  return (
    <svg fill="none" height={100} width={100}>
      <path
        d="M50 5.563A44.438 44.438 0 115.563 50 44.437 44.437 0 0150 5.562zM50 0a50 50 0 100 100A50 50 0 0050 0z"
        fill="#fff"
      />
      <path
        d="M72.5 63.25a2.781 2.781 0 00-3.75 1.172c0 .094-4.234 7.812-15.969 8.953V47.203h10.125a2.781 2.781 0 100-5.562H52.781v-6.563a9.64 9.64 0 10-5.562 0v6.563H37.094a2.782 2.782 0 000 5.562h10.125v26.234c-11.578-1.078-15.797-8.624-15.97-8.953a2.785 2.785 0 10-4.937 2.579c.25.453 5.813 10.796 20.907 11.953v2.062a2.782 2.782 0 005.562 0v-2.125C67.875 77.797 73.437 67.453 73.687 67a2.781 2.781 0 00-1.187-3.75zM45.937 25.844A4.094 4.094 0 1150 29.937a4.094 4.094 0 01-4.094-4.093h.031z"
        fill="#fff"
      />
    </svg>
  );
};

export default AnchorIcon;
