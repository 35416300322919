import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useUploadFilesHooks = () => {
  const [{ data, loading, error }, executeUploadFiles] = useHooks(
    {
      url: `${endpoint}/api/docs/submit`,
      method: 'POST',
    },
    { manual: true }
  );

  return { data, loading, error, executeUploadFiles };
};

export default useUploadFilesHooks;
