import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

import ActiveCheckIcon from 'svg/Flow/ActiveCheckIcon';
import CompleteCheckIcon from 'svg/Flow/CompleteCheckIcon';
import formState from 'common/data/formState';
import NextCheckIcon from 'svg/Flow/NextCheckIcon';

const activeStepImg =
  'https://storage.googleapis.com/bucket-cpa-permits-prod/images/flow/activeStepImg.png';
const completeStepImg =
  'https://storage.googleapis.com/bucket-cpa-permits-prod/images/flow/completeStepImg.png';
const incompleteStepImg =
  'https://storage.googleapis.com/bucket-cpa-permits-prod/images/flow/incompleteStepImg.png';
const nextStepImg =
  'https://storage.googleapis.com/bucket-cpa-permits-prod/images/flow/nextStepImg.png';

const Steps = ({ steps }) => {
  const formValues = useRecoilValue(formState);
  const { activeStep } = formValues;

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" py={5}>
      {(steps || []).map(
        ({ permitApplicationStepId, progressTitle }, index) => {
          if (activeStep === index) {
            return (
              <Box
                display="flex"
                height={100}
                justifyContent="center"
                key={permitApplicationStepId}
                sx={{
                  background: `url(${activeStepImg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: index === 0 ? '320px 100px' : '360px 100px',
                  filter: 'drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1))',
                  marginLeft: index === 0 ? 0 : '-40px',
                  width: index === 0 ? 320 : 360,
                  zIndex: Math.floor(10 / (index + 1)),
                }}
              >
                <Box alignItems="center" display="flex" px={6} py={2}>
                  <Box pr={1}>
                    <ActiveCheckIcon />
                  </Box>
                  <Typography
                    sx={{
                      color: '#ffffff',
                      fontStyle: 'italic',
                      fontWeight: 'bold',
                    }}
                    variant="caption"
                  >
                    {progressTitle}
                  </Typography>
                </Box>
              </Box>
            );
          }

          if (index === activeStep + 1) {
            return (
              <Box
                display="flex"
                height={100}
                justifyContent="center"
                key={permitApplicationStepId}
                sx={{
                  background: `url(${nextStepImg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '360px 100px',
                  filter: 'drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1))',
                  marginLeft: '-40px',
                  width: 360,
                  zIndex: Math.floor(10 / (index + 1)),
                }}
              >
                <Box alignItems="center" display="flex" px={6} py={2}>
                  <Box pr={1}>
                    <NextCheckIcon />
                  </Box>
                  <Typography
                    sx={{
                      color: '#e3e3e3',
                      fontWeight: 'normal',
                    }}
                    variant="caption"
                  >
                    {progressTitle}
                  </Typography>
                </Box>
              </Box>
            );
          }

          if (activeStep > index) {
            return (
              <Box
                display="flex"
                height={100}
                justifyContent="center"
                key={permitApplicationStepId}
                sx={{
                  background: `url(${completeStepImg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: index === 0 ? '320px 100px' : '360px 100px',
                  filter: 'drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1))',
                  marginLeft: index === 0 ? 0 : '-40px',
                  width: index === 0 ? 320 : 360,
                  zIndex: Math.floor(10 / (index + 1)),
                }}
              >
                <Box alignItems="center" display="flex" px={6} py={2}>
                  <Box pr={1}>
                    <CompleteCheckIcon />
                  </Box>
                  <Typography
                    sx={{
                      color: '#010046',
                      fontWeight: 'normal',
                    }}
                    variant="caption"
                  >
                    {progressTitle}
                  </Typography>
                </Box>
              </Box>
            );
          }

          return (
            <Box
              display="flex"
              height={100}
              justifyContent="center"
              key={permitApplicationStepId}
              sx={{
                background: `url(${incompleteStepImg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '360px 100px',
                filter: 'drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.1))',
                marginLeft: '-40px',
                width: 360,
                zIndex: Math.floor(10 / (index + 1)),
              }}
            >
              <Box alignItems="center" display="flex" px={6} py={2}>
                <Box pr={1}>
                  <NextCheckIcon />
                </Box>
                <Typography
                  sx={{
                    color: '#e3e3e3',
                    fontWeight: 'normal',
                  }}
                  variant="caption"
                >
                  {progressTitle}
                </Typography>
              </Box>
            </Box>
          );
        }
      )}
    </Box>
  );
};

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default Steps;
