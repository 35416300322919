import useHooks from 'axios-hooks';

const useCurrentStepHooks = () => {
  const [{ data, loading, error }, executeCurrentStep] = useHooks({
    manual: true,
    useCache: true,
  });

  return { data, loading, error, executeCurrentStep };
};

export default useCurrentStepHooks;
