import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChatBox from 'common/components/ChatBox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { useCurrentStepHooks, usePermitListsApproverHooks } from 'services';
import { pages, userTypeValue } from 'common/utils/constants';
import { withPermissions } from 'common/hooks/withPermissions';
import Footer from 'common/components/Footer';
import formState from 'common/data/formState';
import Loading from 'common/components/Loading';
import PermitListsDashboard from './PermitListsDashboard';
import SearchIcon from 'svg/Dashboard/SearchIcon';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const Dashboard = () => {
  const navigate = useNavigate();
  const [values, setValues] = useRecoilState(formState);
  const [isLoading, setLoading] = useState(false);
  const [permitListsDisplay, setPermitListsDisplay] = useState([]);
  const [searchBusinessName, setBusinessName] = useState('');

  const { accessToken } = values;

  const { executeCurrentStep } = useCurrentStepHooks();
  const {
    data: permitListsGroup,
    loading: isLoadingPermitLists,
    error: errorPermitLists,
  } = usePermitListsApproverHooks({
    manual: !accessToken,
    token: accessToken,
  });

  useEffect(() => {
    if (permitListsGroup) {
      setPermitListsDisplay(permitListsGroup);
    }
  }, [permitListsGroup, setPermitListsDisplay]);

  const onSelectPermit = async (businessName, permitCategory, data) => {
    try {
      setLoading(true);
      const currentStep = await executeCurrentStep({
        url: `${endpoint}/api/applicants/current-step/${data?.permitApplicationLogId}`,
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (currentStep?.status === 200) {
        setValues(state => ({
          ...state,
          applicantStepLogId: currentStep?.data?.permitApplicationStepLogId,
          businessName,
          currentStep: currentStep?.data,
          isLastStep: data?.isLastStep,
          parentUrl: 'Permits',
          permitApplicationLogId: data?.permitApplicationLogId,
          permitCategory,
          status: data?.status === 'New' ? '' : data?.status,
          statusDisplay: data?.status,
        }));
        setLoading(false);

        navigate('/review');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onSearch = text => {
    setLoading(true);
    setBusinessName(text);

    const filterSearch = (permitListsGroup || []).filter(({ companyDetail }) =>
      companyDetail?.businessName.toLowerCase().includes(text.toLowerCase())
    );

    setPermitListsDisplay(filterSearch);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const onClearSort = () => {
    if (searchBusinessName) {
      setLoading(true);
      setBusinessName('');

      setPermitListsDisplay(permitListsGroup);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  if (isLoadingPermitLists || isLoading) {
    return (
      <Box height="40vh" paddingBottom="7px">
        <Loading />
      </Box>
    );
  }
  if (errorPermitLists) return <p>Error!</p>;

  return (
    <Box bgcolor="#f9fbff">
      <Container disableGutters maxWidth="lg">
        <Box display="flex" flexDirection="column" pb="234px" pt={5} px={8}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Box color="#010046">
                <Typography variant="h5">PERMITS</Typography>
              </Box>
              <Box color="#9e9e9e">
                <Typography variant="body1">
                  Lists of permits to be approved
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Button
                className="PermitLists_delete"
                component="label"
                onClick={onClearSort}
                sx={{ bgcolor: '#ffffff', mr: 1, px: 3 }}
              >
                CLEAR
              </Button>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e => {
                          if (searchBusinessName) {
                            onSearch(searchBusinessName);
                          }
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={e => setBusinessName(e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    onSearch(e.target.value);
                  }
                }}
                placeholder="Search business name"
                value={searchBusinessName || ''}
                variant="outlined"
              />
            </Box>
          </Box>

          <Box marginTop="36px">
            {(permitListsDisplay || []).length === 0 && (
              <Box color="#9e9e9e" height="44vh" mt="9px">
                <Typography variant="body1">No Permits submitted.</Typography>
              </Box>
            )}
            {(permitListsDisplay || []).length > 0 && (
              <Grid container marginBottom="calc(100% - 76vh)" spacing={4}>
                <Grid item xs={12}>
                  <Box
                    alignItems="center"
                    display="grid"
                    gridTemplateColumns="15% 35% 30% 10% 10%"
                    px={4}
                  >
                    <Box color="#010046">
                      <Typography sx={{ fontWeight: 400 }} variant="body2">
                        DATE SUBMITTED
                      </Typography>
                    </Box>
                    <Box color="#010046">
                      <Typography sx={{ fontWeight: 400 }} variant="body2">
                        PERMIT CATEGORY
                      </Typography>
                    </Box>
                    <Box color="#010046">
                      <Typography sx={{ fontWeight: 400 }} variant="body2">
                        BUSINESS NAME
                      </Typography>
                    </Box>
                    <Box color="#010046">
                      <Typography sx={{ fontWeight: 400 }} variant="body2">
                        STATUS
                      </Typography>
                    </Box>
                    <Box color="#010046" textAlign="center">
                      <Typography sx={{ fontWeight: 400 }} variant="body2">
                        ACTION
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {(permitListsDisplay || [])
                  .sort(
                    (a, b) =>
                      b.permitApplicationLogId - a.permitApplicationLogId
                  )
                  .map(data => (
                    <Grid item key={data?.permitApplicationLogId} xs={12}>
                      <PermitListsDashboard
                        data={data}
                        onSelectPermit={onSelectPermit}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Container>

      <ChatBox />
      <Footer />
    </Box>
  );
};

export default withPermissions(
  Dashboard,
  userTypeValue.APPROVER,
  pages.DASHBOARD
);
