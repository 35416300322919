import * as React from 'react';

const CloseIcon = () => {
  return (
    <svg fill="none" height={20} width={28}>
      <path
        clipRule="evenodd"
        d="M.509.508c.28-.28.812-.204 1.186.17l17.627 17.627c.375.375.45.906.17 1.186-.281.281-.812.205-1.187-.169L.678 1.695C.304 1.32.228.789.508.508Z"
        fill="#096BEF"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M.508 19.491c-.28-.28-.204-.812.17-1.186L18.305.678c.375-.375.906-.45 1.186-.17.281.281.205.812-.169 1.187L1.695 19.322c-.375.374-.906.45-1.187.17Z"
        fill="#096BEF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CloseIcon;
