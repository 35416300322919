import { Box, Link, Paper, Typography } from '@mui/material';
import { number, shape, string } from 'prop-types';
import React from 'react';

import { dateFormat } from 'common/utils/dateConvert';
import PdfIcon from 'svg/Flow/Requirements/PdfIcon';

const ActiveLists = ({ data }) => {
  const displayDate = dateFormat(data?.expirationDate);

  return (
    <Paper elevation={2}>
      <Box
        alignItems="center"
        display="grid"
        gridTemplateColumns="15% 35% 30% 20%"
        p={4}
      >
        <Box sx={{ color: '#010046', textTransform: 'uppercase' }}>
          <Typography sx={{ fontWeight: 400 }} variant="caption">
            {displayDate || '--'}
          </Typography>
        </Box>
        <Box color="#010046">
          <Typography sx={{ fontWeight: 400 }} variant="caption">
            {data?.permitType || '--'}
          </Typography>
        </Box>
        <Box color="#010046">
          <Typography sx={{ fontWeight: 400 }} variant="caption">
            {data?.businessName || '--'}
          </Typography>
        </Box>
        <Link
          className="Requirements_fileLink"
          href={data?.permitFileUrl}
          rel="noopener"
          target="_blank"
        >
          <PdfIcon />
          <Typography sx={{ pl: 1 }} variant="caption">
            {data?.permitFileName || '--'}
          </Typography>
        </Link>
      </Box>
    </Paper>
  );
};

ActiveLists.propTypes = {
  data: shape({
    businessName: string.isRequired,
    expirationDate: string.isRequired,
    permitApplicationLogId: number.isRequired,
    permitFileName: string.isRequired,
    permitFileUrl: string.isRequired,
    permitType: string.isRequired,
  }).isRequired,
};

export default ActiveLists;
