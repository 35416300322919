import * as React from 'react';

const CheckIcon = () => {
  return (
    <svg fill="none" height={52} width={53}>
      <path d="M6 0a6 6 0 00-6 6v46L53 0H6z" fill="#096BEF" />
      <path
        d="M9 16.72L14.28 22l12-12"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={4}
      />
    </svg>
  );
};

export default CheckIcon;
