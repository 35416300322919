import * as React from 'react';

const SearchIcon = () => {
  return (
    <svg fill="none" height={20} width={22}>
      <path
        d="M13.657 2.662A8 8 0 112.343 13.975 8 8 0 0113.657 2.662z"
        fill="#FFF500"
      />
      <path
        clipRule="evenodd"
        d="M12.781 12.781a6.595 6.595 0 10-9.327-9.327 6.595 6.595 0 009.327 9.327zm1.077 1.077a8.118 8.118 0 10-11.48-11.48 8.118 8.118 0 0011.48 11.48z"
        fill="#096BEF"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.678 12.602a.761.761 0 011.077 0l6.098 6.099a.761.761 0 01-1.076 1.076l-6.099-6.099a.761.761 0 010-1.076z"
        fill="#096BEF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SearchIcon;
