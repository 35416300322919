import { Box } from '@mui/material';
import { string } from 'prop-types';
import { useEffect, useState } from 'react';

import Loading from '../Loading';

export const GoogleDocsViewer = ({ docUrl }) => {
  const [isLoading, setLoading] = useState(true);

  const googleViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
    docUrl
  )}&embedded=true`;

  useEffect(() => {
    if (docUrl) {
      setLoading(true);
    }
  }, [docUrl]);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}

      <Box
        sx={{
          width: '90%',
          height: '90%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          visibility: isLoading ? 'hidden' : 'visible',
        }}
      >
        <iframe
          allowFullScreen
          onLoad={handleIframeLoad}
          src={googleViewerUrl}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            borderRadius: '8px',
          }}
          title="Docs"
        />
      </Box>
    </>
  );
};

GoogleDocsViewer.propTypes = {
  docUrl: string,
};
