import { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  Box,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import './styles.scss';
import formState from 'common/data/formState';
import t from 'common/utils/translates';

const ReviewConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    minHeight: 24,
  },
}));

const ApproverSteps = ({ approverStepsGroup }) => {
  const setValues = useSetRecoilState(formState);

  const isCompleteStep = (approverStepsGroup || []).every(
    ({ stepLog }) => stepLog?.stepEndDate
  );
  const lastStep = (approverStepsGroup || []).at(-1);
  const findActiveStep = (approverStepsGroup || []).find(
    ({ stepLog }) => (stepLog && !stepLog?.stepEndDate) || !stepLog
  );

  const activeStep = findActiveStep
    ? approverStepsGroup.findIndex(
        ({ permitApplicationStepId }) =>
          permitApplicationStepId === findActiveStep.permitApplicationStepId
      )
    : (approverStepsGroup || []).length;

  useEffect(() => {
    if (findActiveStep) {
      setValues(state => ({
        ...state,
        approverStepLogId: findActiveStep?.stepLog?.permitApplicationStepLogId,
      }));
    } else if (isCompleteStep) {
      setValues(state => ({
        ...state,
        approverStepLogId: lastStep?.stepLog?.permitApplicationStepLogId,
      }));
    }
  }, [findActiveStep, isCompleteStep, lastStep, setValues]);

  if (!approverStepsGroup) return null;

  return (
    <Box className="ApproverSteps_container">
      <Stepper
        activeStep={activeStep}
        connector={<ReviewConnector />}
        orientation="vertical"
      >
        {(approverStepsGroup || []).map(
          ({ approvedBy, permitApplicationStepId, progressLabel }) => (
            <Step expanded key={permitApplicationStepId} sx={{ width: '90%' }}>
              <StepLabel sx={{ alignItems: 'flex-start' }}>
                {progressLabel}
              </StepLabel>
              {approvedBy && (
                <StepContent>
                  <Box className="ApproverSteps_perStep">
                    <Typography variant="caption">
                      {t.admin.approverSteps.approved(approvedBy)}
                    </Typography>
                  </Box>
                </StepContent>
              )}
            </Step>
          )
        )}
      </Stepper>
    </Box>
  );
};

ApproverSteps.propTypes = {
  approverStepsGroup: PropTypes.array.isRequired,
};

export default ApproverSteps;
