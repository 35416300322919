import * as React from 'react';

const FourteenthPermitIcon = () => {
  return (
    <svg fill="none" height={55} width={56} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#fourteenth)">
        <path
          d="M30.004 1h-28.5v54h43S35 51.998 35 43.998s9.504-10 9.504-10V15.5h-14.5V1Z"
          fill="#FFF500"
        />
        <path
          d="M8.988 24.875a.728.728 0 0 1-.735-.697v-7.965a6.37 6.37 0 0 1-1.977-4.612c0-3.527 2.878-6.405 6.405-6.405s6.405 2.878 6.405 6.405v.031l-.012.006a6.37 6.37 0 0 1-1.976 4.581v3.776h.012v1.463h-.012v2.694a.729.729 0 0 1-1.045.653l-3.378-1.636-3.377 1.636a.742.742 0 0 1-.31.07Zm0 0v-.05.05Zm6.999-9.601.007-.004a4.917 4.917 0 0 0 1.64-3.675 4.929 4.929 0 0 0-4.941-4.942 4.932 4.932 0 0 0-4.942 4.942c0 1.496.66 2.827 1.706 3.733l.002.002.008.008.003.003a4.928 4.928 0 0 0 3.217 1.19c.152 0 .298-.006.445-.017.135-.011.265-.032.402-.054l2.453-1.186Zm0 0v.002-.002h-.002l-.004.003a.162.162 0 0 0-.028.02.326.326 0 0 1-.025.024h-.001a4.882 4.882 0 0 1-2.363 1.134l2.423-1.181Zm-3.623 6.42-2.642 1.28v-5.702a6.376 6.376 0 0 0 2.96.728 6.414 6.414 0 0 0 2.964-.728v5.702l-2.642-1.28a.752.752 0 0 0-.64 0Zm3.638-6.39.007.014-.007-.015ZM8.148 31.272h28.665a.834.834 0 1 0 0-1.67H8.148a.834.834 0 1 0 0 1.67ZM8.148 39.155h20.404a.834.834 0 1 0 0-1.67H8.148a.834.834 0 1 0 0 1.67ZM8.148 47.041h16.864a.834.834 0 1 0 0-1.67H8.148a.834.834 0 1 0 0 1.67Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeWidth={0.1}
        />
        <path
          d="M44.962 33.402c-5.954 0-10.798 4.845-10.798 10.798C34.164 50.154 39.008 55 44.962 55S55.76 50.154 55.76 44.2c0-5.953-4.844-10.798-10.798-10.798Zm0 19.927c-5.033 0-9.128-4.095-9.128-9.129 0-5.033 4.095-9.128 9.128-9.128s9.129 4.095 9.129 9.128c0 5.034-4.096 9.129-9.129 9.129Z"
          fill="#096BEF"
        />
        <path
          d="M39.045 53.224H1.776V1.776h26.748v13.33c0 .53.354.89.89.89h13.861v17.555a10.62 10.62 0 0 1 1.688-.147V15.106c0-.266-.177-.531-.354-.708L30.034.266a.956.956 0 0 0-.62-.266H.892C.354 0 0 .354 0 .891v53.224c0 .531.354.89.891.89h43.187c.07 0 .13-.023.195-.035a10.771 10.771 0 0 1-5.228-1.74v-.006ZM30.3 3.02l11.642 11.194H30.3V3.02Z"
          fill="#096BEF"
        />
        <path
          d="M43.617 43.843c.484 0 .968-.006 1.458 0 .319.006.507.253.39.513-.048.1-.16.177-.26.23-.065.036-.165.03-.248.03H42.26c-.07 0-.141 0-.212-.018a.362.362 0 0 1-.319-.395c.012-.195.136-.343.343-.354.336-.018.672-.012 1.008-.012h.543l-.005.006ZM46.503 43.848a.39.39 0 0 1 .383.383.387.387 0 0 1-.39.378.374.374 0 0 1-.377-.384c0-.212.171-.377.384-.377ZM47.86 43.848c.207 0 .384.177.378.383a.39.39 0 0 1-.39.378.378.378 0 0 1-.377-.384.37.37 0 0 1 .383-.371l.006-.006ZM43.617 40.74c.484 0 .968-.006 1.457 0 .319 0 .514.253.39.507-.047.1-.171.177-.277.236-.054.036-.136.024-.207.024h-2.874c-.248-.03-.39-.183-.383-.401.006-.213.153-.36.395-.366.42-.012.838 0 1.263 0H43.617ZM46.49 40.742c.207 0 .39.165.396.372a.395.395 0 0 1-.377.39.382.382 0 0 1-.39-.372c0-.213.16-.384.372-.39ZM48.244 41.114a.395.395 0 0 1-.378.39.387.387 0 0 1-.39-.378c0-.213.166-.384.378-.384.207 0 .39.165.39.372ZM43.617 46.947h1.434c.324 0 .525.242.407.495-.047.106-.177.19-.283.254-.06.035-.154.024-.23.024h-2.833c-.254-.024-.401-.172-.401-.39 0-.218.153-.371.401-.383.266-.012.531 0 .797 0h.708ZM46.497 47.714a.383.383 0 0 1-.384-.383c0-.213.171-.378.384-.378a.39.39 0 0 1 .383.384.387.387 0 0 1-.39.377h.007ZM47.854 47.714a.383.383 0 0 1-.383-.383c0-.213.17-.378.383-.378a.39.39 0 0 1 .384.378.385.385 0 0 1-.384.377v.006Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          d="M49.618 46.233c-.106-.141-.236-.265-.366-.407.484-.407.755-.915.755-1.552 0-.631-.283-1.139-.76-1.546.052-.047.082-.077.117-.106.614-.56.809-1.369.508-2.16-.266-.708-1.003-1.221-1.8-1.221-2.071 0-4.142-.006-6.208 0-1.345 0-2.265 1.322-1.829 2.584.124.36.354.655.655.91-.495.412-.767.925-.767 1.557 0 .631.277 1.145.767 1.552-.118.118-.236.212-.33.324-.478.614-.59 1.293-.272 2 .337.744.944 1.14 1.759 1.157.832.018 1.67 0 2.502 0h1.233c.838 0 1.682.018 2.52-.006.79-.023 1.38-.413 1.717-1.133.318-.684.236-1.345-.207-1.959l.006.006Zm-7.89-6.207c.072-.006.142-.012.213-.012h6.048c.573 0 1.004.295 1.169.785.26.767-.278 1.528-1.11 1.54-.696.011-1.398 0-2.094 0H41.9c-.614 0-1.092-.39-1.186-.962a1.154 1.154 0 0 1 1.009-1.357l.006.006Zm6.592 8.49a1.613 1.613 0 0 1-.29.036h-6.124c-.602 0-1.068-.377-1.18-.926-.148-.72.371-1.375 1.127-1.399.4-.011.802 0 1.203 0h4.963c.566 0 1.009.319 1.162.82.2.644-.194 1.328-.855 1.476l-.006-.006Zm-.295-3.073c-1.021.005-2.042 0-3.063 0h-3.044c-.62 0-1.098-.384-1.198-.95a1.156 1.156 0 0 1 1.015-1.37c.065-.005.13-.011.194-.011h6.072c.702 0 1.222.49 1.227 1.15.006.661-.507 1.175-1.203 1.175v.006Z"
          fill="#096BEF"
          stroke="#096BEF"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
      </g>
      <defs>
        <clipPath id="fourteenth">
          <path d="M0 0h55.761v55H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FourteenthPermitIcon;
