import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useGroupChatHooks = ({ manual = true, token }) => {
  const [{ data, loading, error }, executeGroupChat] = useHooks(
    {
      url: `${endpoint}/api/chats/group`,
      headers: { Authorization: `Bearer ${token}` },
    },
    { manual, useCache: true }
  );

  return { data, loading, error, executeGroupChat };
};

export default useGroupChatHooks;
