import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const usePermitTypeHooks = () => {
  const [{ data, loading, error }, executePermitType] = useHooks(
    { url: `${endpoint}/api/permits/steps` },
    { manual: true, useCache: true }
  );

  return { data, loading, error, executePermitType };
};

export default usePermitTypeHooks;
