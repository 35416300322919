import * as React from 'react';

const NavigationIcon = () => {
  return (
    <svg fill="none" height={12} width={28}>
      <path
        clipRule="evenodd"
        d="M0 .75C0 .336.448 0 1 0h26c.552 0 1 .336 1 .75s-.448.75-1 .75H1c-.552 0-1-.336-1-.75ZM0 10.75c0-.414.448-.75 1-.75h26c.552 0 1 .336 1 .75s-.448.75-1 .75H1c-.552 0-1-.336-1-.75Z"
        fill="#096BEF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default NavigationIcon;
