import * as React from 'react';

const ChatMessageIcon = () => {
  return (
    <svg fill="none" height={40} width={40} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0h36a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4H0V0Z"
        fill="url(#chatMessage)"
      />
      <path
        clipRule="evenodd"
        d="m22.5 24.866-1.547-4.64 4.64-4.64-3.094 9.28Zm1.914-10.459-4.64 4.64-4.64-1.546 9.28-3.094Zm3.91-1.95a.792.792 0 0 0-.073-.3.805.805 0 0 0-.163-.245.799.799 0 0 0-.32-.192.825.825 0 0 0-.229-.045c-.018 0-.035-.005-.054-.005a.859.859 0 0 0-.249.04l-15 5a.833.833 0 0 0 0 1.582l7.104 2.368 2.37 7.105a.832.832 0 0 0 1.58 0l5-15a.83.83 0 0 0 .04-.25c0-.02-.005-.038-.006-.058Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="chatMessage"
          x1={42.25}
          x2={6}
          y1={2}
          y2={35.25}
        >
          <stop stopColor="#FFF500" />
          <stop offset={1} stopColor="#FF8A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChatMessageIcon;
