import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Breadcrumb = ({ parent, step, subParent }) => {
  const breadcrumbs = [
    <Link href="/" key="1" underline="hover">
      <Typography sx={{ color: '#c0c0c0' }} variant="caption">
        {parent}
      </Typography>
    </Link>,
    <Typography key="2" sx={{ color: '#c0c0c0' }} variant="caption">
      {subParent}
    </Typography>,
    <Typography
      key="3"
      sx={{
        color: '#096bef',
        fontStyle: 'italic',
        textDecoration: 'underline',
        textDecorationColor: '#096bef',
      }}
      variant="caption"
    >
      {step}
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

Breadcrumb.propTypes = {
  parent: PropTypes.string.isRequired,
  step: PropTypes.string,
  subParent: PropTypes.string.isRequired,
};

export default Breadcrumb;
