import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useRecoilValue } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import cn from 'classnames';

import {
  Avatar,
  Box,
  Fade,
  Popper,
  TextField,
  Typography,
} from '@mui/material';

import './styles.scss';
import { dateFormat } from 'common/utils/dateConvert';
import { useGroupChatHooks, useMessagesHooks } from 'services';
import { userTypeValue } from 'common/utils/constants';
import formState from 'common/data/formState';
import t from 'common/utils/translates';
import u from 'common/utils/urls';

import ChatIcon from 'svg/Common/ChatIcon';
import ChatCloseIcon from 'svg/Common/ChatCloseIcon';
import ChatLogoIcon from 'svg/Common/ChatLogoIcon';
import ChatMessageIcon from 'svg/Common/ChatMessageIcon';
import Loading from 'common/components/Loading';
import ScrollContainer from './ScrollContainer';

const ChatBox = () => {
  const { accessToken, user, userType } = useRecoilValue(formState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const [messages, setMessages] = useState(null);
  const [message, setMessage] = useState('');
  const [hasSelectedGC, setSelectedGC] = useState(false);
  const [ownerData, setOwnerData] = useState(null);
  const [title, setTitle] = useState('CPA');

  const [connection, setConnection] = useState();
  const [, setUsers] = useState([]);

  const { data: groupChatData, loading: isLoadingGc } = useGroupChatHooks({
    manual: !accessToken,
    token: accessToken,
  });

  const groupChat = (groupChatData || []).find(({ isOwner }) => isOwner);

  const { loading: isLoadingMessages, executeMessages } = useMessagesHooks({
    token: accessToken,
  });

  const joinRoom = useCallback(
    async groupChatId => {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl(process.env.REACT_APP_CHAT_ENDPOINT, {
            accessTokenFactory: () => accessToken,
          })
          .configureLogging(LogLevel.Information)
          .build();

        connection.on('ReceiveMessage', (user, email, message) => {
          setMessages(messages => [...messages, { user, email, message }]);
        });

        connection.on('UsersInGroupChat', users => {
          setUsers(users);
        });

        connection.onclose(e => {
          setConnection();
          setMessages([]);
          setUsers([]);
        });

        await connection.start();
        await connection.invoke('JoinGroupChat', Number(groupChatId));
        setConnection(connection);
      } catch (e) {
        console.error(e);
      }
    },
    [accessToken]
  );

  const getMessages = useCallback(
    async groupChatId => {
      try {
        const response = await executeMessages({
          params: {
            groupChatId: groupChatId,
          },
        });
        setMessages(response.data);
      } catch (e) {
        console.error(e);
      }
    },
    [executeMessages]
  );

  useEffect(() => {
    if (groupChat) {
      setOwnerData(groupChat);
      joinRoom(groupChat.groupChatId);
    }
  }, [groupChat, joinRoom]);

  useEffect(() => {
    if (!messages && userType === userTypeValue.APPLICANT && groupChat) {
      getMessages(groupChat.groupChatId);
    }
  }, [getMessages, groupChat, messages, userType]);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(previousOpen => !previousOpen);
  };

  const handleBack = async () => {
    try {
      await connection.stop();
      setTitle('CPA');
      setSelectedGC(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const InputComponentProps = {
    classes: {
      input: 'input',
      notchedOutline: 'notchedOutline',
      root: 'root',
    },
  };

  const sendMessage = async e => {
    try {
      e.preventDefault();
      await connection.invoke('SendMessage', message);

      setMessage('');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box className="ChatBox_container">
      <Box className="ChatBox_icon" onClick={handleClick}>
        <ChatIcon />
      </Box>

      <Popper anchorEl={anchorEl} open={isOpen} placement="top-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className="ChatBox_section">
              <Box className="ChatBox_header">
                <Box className="headerFlex">
                  <Box className="title">
                    <Box className="logo">
                      <ChatLogoIcon />
                    </Box>
                    <Typography variant="h6">{title}</Typography>
                  </Box>
                  <Box className="menu">
                    {hasSelectedGC && (
                      <Box className="back" onClick={handleBack}>
                        <ArrowBackIcon />
                      </Box>
                    )}
                    <Box className="close" onClick={handleClose}>
                      <ChatCloseIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="ChatBox_display">
                {(isLoadingMessages || isLoadingGc) && (
                  <Box>
                    <Loading />
                  </Box>
                )}
                <ScrollContainer>
                  {userType === userTypeValue.APPROVER &&
                    !hasSelectedGC &&
                    !isLoadingGc &&
                    (groupChatData || []).map(gc => {
                      return (
                        <Box
                          className="Chatbox_groupchat"
                          key={gc.groupChatId}
                          onClick={e => {
                            e.preventDefault();
                            joinRoom(gc.groupChatId);
                            getMessages(gc.groupChatId);
                            setSelectedGC(true);
                            setOwnerData(gc);
                            setTitle(gc.name);
                          }}
                        >
                          <Box className="displayGroup">
                            <Avatar
                              src={u.userImg}
                              sx={{ width: 24, height: 24 }}
                            />
                            <Box className="gcName">
                              <Typography variant="body1">{gc.name}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  {(userType === userTypeValue.APPLICANT || hasSelectedGC) &&
                    !isLoadingMessages &&
                    (messages || []).map((m, index) => {
                      const currentUser =
                        m?.senderEmail === user.email ||
                        m?.email === user.email;
                      const name =
                        ownerData?.ownerEmail === m?.senderEmail ||
                        ownerData?.ownerEmail === m?.email
                          ? ownerData.name
                          : m?.senderName || m?.user;
                      const date = m?.timeStamp
                        ? dateFormat(m.timeStamp, 'PP')
                        : 'Just now';
                      const display = name.concat(` ${date}`);
                      const cpa = 'Cebu Port Authority';
                      const cpaDisplay = cpa.concat(` ${date}`);

                      if (currentUser) {
                        return (
                          <Box
                            className={cn('Chatbox_messageContainer', {
                              currentUser,
                            })}
                            key={index}
                          >
                            <Box
                              className={cn('Chatbox_message', { currentUser })}
                              key={index}
                            >
                              <Typography
                                className={cn('display', { currentUser })}
                                variant="caption"
                              >
                                {display}
                              </Typography>
                              <Box className={cn('inputs', { currentUser })}>
                                <Typography
                                  variant="body2"
                                  whiteSpace="pre-wrap"
                                >
                                  {m.message}
                                </Typography>
                              </Box>
                            </Box>
                            <Avatar
                              src={u.userImg}
                              sx={{ width: 24, height: 24 }}
                            />
                          </Box>
                        );
                      }

                      return (
                        <Box className="Chatbox_messageContainer" key={index}>
                          <Avatar
                            src={u.userImg}
                            sx={{ width: 24, height: 24 }}
                          />

                          <Box className="Chatbox_message">
                            <Typography className="display" variant="caption">
                              {userType === userTypeValue.APPLICANT
                                ? cpaDisplay
                                : display}
                            </Typography>
                            <Typography
                              className="inputs"
                              variant="body2"
                              whiteSpace="pre-wrap"
                            >
                              {m.message}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </ScrollContainer>
              </Box>

              {(userType === userTypeValue.APPLICANT || hasSelectedGC) && (
                <Box className="ChatBox_footer">
                  <Box className="inputFields">
                    <TextField
                      InputProps={InputComponentProps}
                      multiline
                      onChange={e => setMessage(e.target.value)}
                      // onKeyDown={e => {
                      //   if (e.keyCode === 13) sendMessage(e);
                      // }}
                      placeholder={t.chat.write}
                      value={message}
                      variant="outlined"
                    />

                    <Box className="sendMessage" onClick={sendMessage}>
                      <ChatMessageIcon />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default ChatBox;
