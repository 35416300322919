import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import React from 'react';
import ReactDOM from 'react-dom/client';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import App from './App';
import ErrorBoundary from 'common/components/ErrorBoundary';
import Loading from 'common/components/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <RecoilRoot>
        <React.Suspense fallback={<Loading />}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </React.Suspense>
      </RecoilRoot>
    </BrowserRouter>
  </ErrorBoundary>
);
