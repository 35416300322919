import * as React from 'react';

const SecondPermitIcon = () => {
  return (
    <svg fill="none" height={60} width={58}>
      <path
        d="M1.933 14.983L11.6 10.15l26.583 14.017L29 29 1.933 14.983zM19.817 6.283l9.666-4.833 26.584 14.017-9.184 4.833L19.817 6.283zM.967 16.433v27.55L28.517 58V30.45L.967 16.433zM29.483 29.967l9.667-5.317v6.283L43.5 26.1l3.383 1.45v-6.767L56.97 15.95v28.033L29.483 58V29.967z"
        fill="#FFF500"
      />
      <path
        d="M57.94 15.076c-.061-.121-.061-.182-.183-.303-.06-.121-.182-.182-.364-.243L43.5 7.31 29.425.092a.9.9 0 00-.789 0L14.5 7.311.546 14.53a.733.733 0 00-.425.424c-.06.121-.06.182-.121.303v28.94c0 .303.182.606.485.788l28.09 14.38s.061 0 .061.06c0 0 .06 0 .06.06.122.061.183.061.304.061s.182 0 .303-.06c0 0 .061 0 .061-.061 0 0 .06 0 .06-.06l28.09-14.38c.304-.182.486-.485.486-.788V15.319c0-.121 0-.182-.06-.243zM29 1.85l13.65 7.038 12.56 6.431-8.616 4.429-26.21-13.469L29 1.85zm11.406 22.994l3.033-1.577 2.002-1.032v3.762l-1.638-.728a.842.842 0 00-1.031.243l-2.305 2.851v-3.519h-.061zM15.349 8.888l3.095-1.577 26.209 13.408-5.096 2.67-26.21-13.47 2.002-1.03zm12.802 48.354L1.759 43.652V16.775l12.802 6.552 13.59 6.977v26.938zM29 28.788L15.35 21.75 2.79 15.319l8.616-4.429 26.21 13.469L29 28.788zm27.24 14.924L29.85 57.181V30.304l8.857-4.55v5.096c0 .486.364.85.85.85a.865.865 0 00.727-.364l3.398-4.186 2.305.97c.425.182.971 0 1.153-.485.06-.06.06-.182.06-.303v-5.946l9.04-4.61v26.936z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default SecondPermitIcon;
