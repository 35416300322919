import * as React from 'react';

const NinthPermitIcon = () => {
  return (
    <svg fill="none" height={60} width={43}>
      <path
        d="M1 1.5h40.5V54h-14v-7.5c1-1.667 2.5-5.1 2.5-7.5 0-4.5-3-9-8.5-9s-9 4.5-9 8.5c0 3.2 2.333 6.667 3.5 8V53H1V1.5z"
        fill="#FFF500"
      />
      <path
        d="M.972 0C.417.07 0 .486 0 1.042v52.5c0 .555.486 1.041 1.042 1.041h13.541v4.306c0 .625.486 1.042 1.111 1.042.14 0 .278-.07.487-.14l5.347-2.569 5.347 2.57c.556.277 1.18 0 1.458-.486.07-.14.07-.278.14-.487v-4.305h13.471c.625 0 1.042-.486 1.042-1.042V1.042C42.847.486 42.361 0 41.806 0H.972zm1.18 2.153h38.542V52.5h-12.43v-6.042c1.875-1.805 3.125-4.375 3.125-7.222 0-5.486-4.514-10-10-10s-10 4.514-10 10c0 2.847 1.18 5.417 3.125 7.222V52.5H2.153V2.153zm12.431 5.694c-.625 0-1.11.486-1.11 1.042 0 .625.485 1.111 1.04 1.111H28.195c.625 0 1.112-.486 1.112-1.042 0-.625-.487-1.11-1.042-1.11h-13.68zm17.014 7.5H11.181c-.556.07-1.042.625-.973 1.18.07.556.486.973 1.042.973h20.139c.555.07 1.111-.417 1.18-.972.07-.556-.416-1.111-.972-1.18.07 0 .07 0 0 0zm-17.083 7.43c-.625.07-1.042.556-.972 1.181.07.556.555 1.042 1.11.973h13.612c.625 0 1.111-.487 1.111-1.042 0-.625-.486-1.111-1.042-1.111h-13.82zm6.944 8.681c4.306 0 7.848 3.473 7.848 7.848a7.705 7.705 0 01-2.64 5.833h-.069s-.07 0-.07.07c-1.04.902-2.36 1.527-3.68 1.805-.208.07-.486.07-.694.07h-.695c-1.875 0-3.68-.695-5.139-1.876-1.666-1.458-2.708-3.541-2.708-5.902a7.795 7.795 0 017.847-7.848zm-4.722 16.598c1.389.763 2.986 1.18 4.722 1.18 1.736 0 3.334-.417 4.723-1.18v9.235l-4.237-2.083a1.03 1.03 0 00-.902 0l-4.236 2.084v-9.236h-.07z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default NinthPermitIcon;
