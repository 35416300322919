import { array, string } from 'prop-types';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import cn from 'classnames';

import '../styles.scss';

const NavigationLinks = ({
  menus = null,
  name,
  selectedLink = null,
  url = null,
}) => {
  const [menuLinks, setMenuLinks] = useState(null);
  const navigate = useNavigate();
  const menuUrl = menus?.find(({ url }) => url === selectedLink);

  return (
    <Box px={1}>
      <Button
        className={cn('NavigationBar_links', {
          isSelected: selectedLink === (url || menuUrl?.url),
        })}
        onClick={e => (menus ? setMenuLinks(e.currentTarget) : navigate(url))}
      >
        <Typography variant="button">{name}</Typography>
      </Button>

      <Menu
        anchorEl={menuLinks}
        id="popupUser"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={() => setMenuLinks(null)}
        open={Boolean(menuLinks)}
      >
        {(menus || []).map(({ name, hasFile, url }) => (
          <MenuItem
            key={`menu-links-${name}`}
            onClick={() => {
              if (hasFile) {
                window.open(url, '_blank').focus();
              } else {
                navigate(url);
              }
              setMenuLinks(null);
            }}
            sx={{
              color: '#000000',
            }}
          >
            <Typography variant="button">{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

NavigationLinks.propTypes = {
  menus: array,
  name: string.isRequired,
  selectedLink: string,
  url: string,
};

export default NavigationLinks;
