import { pages, userTypeValue } from 'common/utils/constants';

export const permissions = [
  {
    userType: userTypeValue.APPLICANT,
    permissions: [pages.HOME, pages.FLOW, pages.PERMITS],
  },
  {
    userType: userTypeValue.APPROVER,
    permissions: [pages.DASHBOARD, pages.REVIEW],
  },
  {
    userType: userTypeValue.VISITOR,
    permissions: [pages.HOME, pages.VISITOR],
  },
];

export const resourceActions = name => {
  const permission = (permissions || []).find(
    ({ userType }) => userType === name
  );

  if (permission) {
    return permission;
  }

  return null;
};
