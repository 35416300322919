import useHooks from 'axios-hooks';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const useUserRoleHooks = () => {
  const [{ data, loading, error }, executeUserRole] = useHooks(
    { url: `${endpoint}/api/roles` },
    { manual: true, useCache: true }
  );

  return { data, loading, error, executeUserRole };
};

export default useUserRoleHooks;
