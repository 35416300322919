const urls = {
  appXls:
    'https://docs.google.com/viewer?url=https%3A%2F%2Fstorage.googleapis.com%2Fbucket-cpa-permits-prod%2Fforms%2FAPP.xlsx',
  avpPdf:
    'https://docs.google.com/viewer?url=https%3A%2F%2Fstorage.googleapis.com%2Fbucket-cpa-permits-prod%2Fforms%2FFM-PSD-01.pdf',
  gcashImg:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/images/header/gcashqr.jpg',
  logoImg:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/images/header/logoImg.png',
  userImg:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/images/header/useImg.png',
};

export default urls;
