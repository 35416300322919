import axios from 'axios';

const loginApi = process.env.REACT_APP_LOGIN_API;

const getLoginApi = async token => {
  const response = await axios.get(`${loginApi}/api/account?token=${token}`);

  return response.data;
};

export default getLoginApi;
