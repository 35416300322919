import { Box, Container, Typography } from '@mui/material';
import React from 'react';

import './styles.scss';
import t from 'common/utils/translates';
import u from 'common/utils/urls';

const Footer = () => {
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ backgroundColor: '#096bef' }}
    >
      <Box className="Footer_container">
        <Box className="Footer_logo">
          <img alt="logo" height="48" src={u.logoImg} width="48" />
          <Box color="#ffffff" ml={2}>
            <Typography variant="h6">{t.footer.cpa}</Typography>
          </Box>
        </Box>
        <Box color="#ffffff">
          <Typography variant="body1">{t.footer.copyright}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Footer;
