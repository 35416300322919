import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React, { useEffect } from 'react';

import { pages, userTypeValue } from 'common/utils/constants';
import { withPermissions } from 'common/hooks/withPermissions';
import Breadcrumb from 'common/components/Breadcrumb';
import Footer from 'common/components/Footer';
import formState from 'common/data/formState';
import Steps from './Steps';
import SubSteps from './SubSteps';

const Flow = () => {
  const navigate = useNavigate();
  const formValues = useRecoilValue(formState);
  const { activeStep, parentUrl, permitSteps, permitType } = formValues;

  const selectedStep = (permitSteps?.steps || [])[activeStep];

  useEffect(() => {
    if (!permitType) {
      navigate('/');
    }
  }, [navigate, permitType]);

  if (!permitType) return null;

  return (
    <Container disableGutters maxWidth={false}>
      <Box
        bgcolor="#f9fbff"
        display="flex"
        flexDirection="column"
        pb={8}
        pt={2}
        px={8}
      >
        <Breadcrumb
          parent={parentUrl}
          step={selectedStep?.progressLabel}
          subParent={permitType?.name}
        />
        <Steps steps={permitSteps?.steps} />
        <SubSteps
          currentStep={permitSteps?.currentStep}
          step={selectedStep}
          subSteps={selectedStep?.subSteps}
        />
      </Box>

      <Footer />
    </Container>
  );
};

export default withPermissions(Flow, userTypeValue.APPLICANT, pages.FLOW);
