import * as React from 'react';

const SixthPermitIcon = () => {
  return (
    <svg fill="none" height={52} width={61}>
      <path
        d="M2 13.5a4 4 0 014-4h48.5a4 4 0 014 4v32a4 4 0 01-4 4H6a4 4 0 01-4-4v-32z"
        fill="#FFF500"
      />
      <path d="M26.5 26v-5h7v5.5h6v7h-6v6H26v-6h-5.5V26h6z" fill="#fff" />
      <path
        d="M60 14.043c0-3.225-2.62-5.913-5.913-5.913H41.456V3.225A3.226 3.226 0 0038.23 0H21.702a3.226 3.226 0 00-3.225 3.225v4.972H5.241A5.247 5.247 0 000 13.437v33.058c0 2.89 2.352 5.24 5.24 5.24h48.914c3.226 0 5.913-2.62 5.913-5.912v-31.78H60zM20.896 2.284h18.208V8.13H20.896V2.284zm36.752 43.54c0 1.948-1.612 3.493-3.494 3.493H5.242c-1.613 0-2.822-1.277-2.822-2.822V13.371c0-1.613 1.276-2.822 2.822-2.822h48.913c1.949 0 3.494 1.612 3.494 3.494v31.78z"
        fill="#096BEF"
      />
      <path
        d="M39.306 25.33H34.67v-4.636c0-.672-.538-1.142-1.143-1.142H26.54c-.672 0-1.142.538-1.142 1.142v4.636h-4.636c-.672 0-1.143.538-1.143 1.143v6.987c0 .672.538 1.142 1.143 1.142h4.636v4.637c0 .671.537 1.142 1.142 1.142h6.987c.672 0 1.143-.538 1.143-1.142v-4.636h4.636c.672 0 1.142-.538 1.142-1.143v-6.987c0-.672-.47-1.143-1.142-1.143zm-.74 7.055h-4.904c-.672 0-1.21.538-1.21 1.21v4.904h-4.904v-4.904c0-.672-.538-1.21-1.21-1.21h-4.905v-4.837h4.905c.672 0 1.21-.538 1.21-1.21v-4.905h4.904v4.905c0 .672.538 1.21 1.21 1.21h4.905v4.837z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default SixthPermitIcon;
