import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import { dateFormat } from 'common/utils/dateConvert';
import ApproverSteps from '../ApproverSteps';
import CheckIcon from 'svg/Flow/Requirements/CheckIcon';
import DeleteFileIcon from 'svg/Flow/Requirements/DeleteFileIcon';
import DeleteModal from 'common/components/DeleteModal';
import formState from 'common/data/formState';
import Loading from 'common/components/Loading';
import PdfIcon from 'svg/Flow/Requirements/PdfIcon';
import RadioGroupField from 'common/components/RadioGroupField';
import UncheckIcon from 'svg/Flow/Requirements/UncheckIcon';
import UploadFilesModal from 'common/components/UploadModal';

import {
  useApproverActionHooks,
  useApproverStepsHooks,
  useDeleteFilesHooks,
  useDownloadDocsHooks,
  useRequirementsHooks,
  useUploadFilesApproverHooks,
} from 'services';

import { deleteItemModal, uploadFilesModal } from 'common/data/modalState';

const statusText = {
  Approve: { color: '#2bb673' },
  'In Review': { color: '#096bef' },
  New: { color: '#a3a3a3' },
  Reject: { color: '#ff0000' },
  Pending: { color: '#ff7a00' },
};

const statusGroupOptions = [
  { label: 'IN REVIEW', value: 'In Review' },
  { label: 'PENDING', value: 'Pending' },
  { label: 'APPROVE', value: 'Approve' },
  { label: 'REJECT', value: 'Reject' },
];

const PermitDetails = () => {
  const navigate = useNavigate();
  const setOpenDeleteModal = useSetRecoilState(deleteItemModal);
  const setOpenFilesModal = useSetRecoilState(uploadFilesModal);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useRecoilState(formState);
  const {
    accessToken,
    businessName,
    currentStep,
    isLastStep,
    permitApplicationLogId,
    permitCategory,
    status,
    statusDisplay,
  } = values;

  const statusFormat = statusText[statusDisplay];
  const displayDate = dateFormat(currentStep?.stepStartDate);

  const { executeApproverAction } = useApproverActionHooks();
  const { executeDeleteFiles } = useDeleteFilesHooks();
  const { error: errorUploadFiles, executeUploadFilesApprover } =
    useUploadFilesApproverHooks();
  const {
    data: requirementsGroups,
    loading: isLoadingRequirements,
    error: errorRequirements,
    executeRequirements,
  } = useRequirementsHooks({
    manual: !accessToken,
    permitId: permitApplicationLogId,
    subStepId: currentStep?.permitApplicationStepId,
    token: accessToken,
  });
  const {
    data: approverStepsGroup,
    loading: isLoadingApproverSteps,
    error: errorApproverSteps,
  } = useApproverStepsHooks({
    manual: !accessToken,
    permitApplicationLogId: permitApplicationLogId,
    token: accessToken,
  });
  const { executeDownloadDocs } = useDownloadDocsHooks({
    token: accessToken,
  });

  const onCancel = () => {
    setValues(state => ({
      ...state,
      currentStep: null,
    }));
    navigate('/dashboard');
  };

  const onSavePermit = async () => {
    try {
      setLoading(true);

      const permitData = await executeApproverAction({
        data: {
          action: status,
          applicantPermitApplicationStepLogId:
            currentStep?.permitApplicationStepLogId,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (permitData?.status === 204) {
        setLoading(false);
        navigate('/dashboard');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onDeleteFile = async id => {
    try {
      setLoading(true);

      const deleteFile = await executeDeleteFiles({
        params: { submittedDocumentId: id },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (deleteFile.status === 204) {
        const requirementsData = await executeRequirements({
          params: {
            permitApplicationStepId: currentStep?.permitApplicationStepId,
            permitApplicationLogId: permitApplicationLogId,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (requirementsData?.status === 200) {
          setValues(state => ({
            ...state,
            deleteId: null,
            deleteName: null,
          }));
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      setValues(state => ({
        ...state,
        deleteId: null,
        deleteName: null,
      }));
      setLoading(false);
    }
  };

  const onDownloadDocs = async () => {
    try {
      const response = await executeDownloadDocs({
        params: {
          applicationLogId: permitApplicationLogId,
        },
      });

      if (response) {
        const url = response?.data;
        window.open(url, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoadingRequirements || isLoadingApproverSteps || isLoading) {
    return (
      <Box py={20}>
        <Loading />
      </Box>
    );
  }
  if (errorApproverSteps || errorRequirements) {
    return <p>Error!</p>;
  }

  return (
    <Container disableGutters maxWidth={false}>
      <Box display="flex">
        <ApproverSteps approverStepsGroup={approverStepsGroup} />
        <Box />
        <Container disableGutters maxWidth="lg" sx={{ mt: 5, zIndex: 1 }}>
          <Paper elevation={2} sx={{ width: '100%' }}>
            <Box display="flex" flexDirection="column" p={5}>
              <Box color="#096bef">
                <Typography variant="body1">
                  <strong>PERMIT DETAILS</strong>
                </Typography>
              </Box>

              <Box display="grid" gridTemplateColumns="50% 50%" mt={3}>
                <Box display="flex" flexDirection="column">
                  <Box color="#000000">
                    <Typography variant="caption">
                      <strong>STATUS</strong>
                    </Typography>
                  </Box>
                  <Box color={statusFormat.color}>
                    <Typography variant="body2">{statusDisplay}</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box color="#000000">
                    <Typography variant="caption">
                      <strong>DATE SUBMITTED</strong>
                    </Typography>
                  </Box>
                  <Box color="#010046">
                    <Typography variant="body2">{displayDate}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box display="grid" gridTemplateColumns="50% 50%" mt={3}>
                <Box display="flex" flexDirection="column">
                  <Box color="#000000">
                    <Typography variant="caption">
                      <strong>PERMIT CATEGORY</strong>
                    </Typography>
                  </Box>
                  <Box color="#010046">
                    <Typography variant="body2">{permitCategory}</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box color="#000000">
                    <Typography variant="caption">
                      <strong>BUSINESS NAME</strong>
                    </Typography>
                  </Box>
                  <Box color="#010046">
                    <Typography variant="body2">{businessName}</Typography>
                  </Box>
                </Box>
              </Box>

              {(requirementsGroups || []).length > 0 && (
                <Box display="flex" flexDirection="column" mt={3}>
                  <Box color="#000000" mb={1}>
                    <Typography variant="caption">
                      <strong>LIST OF REQUIREMENTS</strong>
                    </Typography>
                  </Box>
                  {(requirementsGroups || []).map(
                    (
                      {
                        label,
                        name,
                        permitApplicationDocuments,
                        permitRequirementsChecklistId,
                      },
                      index
                    ) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        key={permitRequirementsChecklistId}
                      >
                        <Box pb={3}>
                          <Typography
                            sx={{ fontWeight: 'normal' }}
                            variant="body2"
                          >
                            {String(index + 1).concat('. ', label || name, ';')}
                          </Typography>
                        </Box>

                        <Box display="flex" flexDirection="column" pl={2}>
                          {(permitApplicationDocuments || []).map(
                            ({
                              isReadonlyForApplicant,
                              label,
                              permitApplicationDocumentId,
                              submittedDocuments,
                            }) => (
                              <Box
                                display="flex"
                                flexDirection="column"
                                key={permitApplicationDocumentId}
                                pb={2}
                              >
                                <Box
                                  borderBottom="1px solid #e3e3e3"
                                  display="flex"
                                  flexDirection="row"
                                >
                                  <Box>
                                    {(submittedDocuments || []).length === 0 ? (
                                      <UncheckIcon />
                                    ) : (
                                      <CheckIcon />
                                    )}
                                  </Box>
                                  <Box ml={1} pb={1}>
                                    <Typography variant="body2">
                                      {label}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  mt={1}
                                  width="100%"
                                >
                                  {(submittedDocuments || []).length > 0 && (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      py={1}
                                    >
                                      {(submittedDocuments || []).map(
                                        ({
                                          fileName,
                                          submittedDocumentId,
                                          url,
                                        }) => {
                                          const isXls =
                                            url.match(/\.(xls|xlsx)$/) != null;
                                          let urlLink = url;

                                          if (isXls) {
                                            urlLink = `https://docs.google.com/gview?url=${encodeURIComponent(
                                              urlLink
                                            )}&embedded=true`;
                                          }

                                          return (
                                            <Box
                                              alignItems="center"
                                              display="flex"
                                              justifyContent="space-between"
                                              key={submittedDocumentId}
                                              px={1}
                                              py={1}
                                            >
                                              <Link
                                                className="Requirements_fileLink"
                                                href={urlLink}
                                                rel="noopener"
                                                target="_blank"
                                              >
                                                <PdfIcon />
                                                <Typography
                                                  sx={{ pl: 1 }}
                                                  variant="caption"
                                                >
                                                  {fileName}
                                                </Typography>
                                              </Link>
                                              {isReadonlyForApplicant && (
                                                <Box
                                                  onClick={() => {
                                                    setValues(state => ({
                                                      ...state,
                                                      deleteId:
                                                        submittedDocumentId,
                                                      deleteName: fileName,
                                                    }));
                                                    setOpenDeleteModal(true);
                                                  }}
                                                  sx={{ cursor: 'pointer' }}
                                                >
                                                  <DeleteFileIcon />
                                                </Box>
                                              )}
                                            </Box>
                                          );
                                        }
                                      )}
                                    </Box>
                                  )}
                                  {(submittedDocuments || []).length === 0 && (
                                    <Box color="#c0c0c0" ml={1}>
                                      <Typography variant="body2">
                                        No Attachment
                                      </Typography>
                                    </Box>
                                  )}
                                  {isReadonlyForApplicant && (
                                    <Box
                                      display="flex"
                                      justifyContent="flex-end"
                                      py={1}
                                    >
                                      <Typography
                                        className="Requirements_upload"
                                        onClick={() => {
                                          setValues(state => ({
                                            ...state,
                                            permitApplicationDocumentId,
                                            activeSubStep:
                                              currentStep?.permitApplicationStepId,
                                          }));
                                          setOpenFilesModal(true);
                                        }}
                                        variant="caption"
                                      >
                                        Upload Attachment
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )
                          )}
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              )}

              <Box
                borderBottom="1px solid #e3e3e3"
                display="flex"
                justifyContent="center"
                py={3}
              >
                <Button
                  className="PermitLists_delete"
                  component="label"
                  onClick={onDownloadDocs}
                  sx={{ px: 7, py: 2 }}
                >
                  <Typography variant="body2">
                    <strong>DOWNLOAD ALL ATTACHMENTS</strong>
                  </Typography>
                </Button>
              </Box>

              {isLastStep && (
                <Box
                  borderBottom="1px solid #e3e3e3"
                  display="flex"
                  justifyContent="flex-start"
                  py={2}
                >
                  <Typography
                    className="Requirements_upload"
                    onClick={() => {}}
                    variant="caption"
                  >
                    Upload Attachment
                  </Typography>
                </Box>
              )}

              <Box display="flex" pt={1}>
                <RadioGroupField
                  id="status"
                  onChange={e => {
                    setValues(state => ({
                      ...state,
                      status: e.target.value,
                    }));
                  }}
                  options={statusGroupOptions}
                  value={status}
                />
              </Box>
            </Box>
          </Paper>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            py={5}
          >
            <Button
              className="PermitLists_delete"
              component="label"
              onClick={onCancel}
              sx={{ px: 7, py: 2 }}
            >
              <Typography variant="body2">
                <strong>CANCEL</strong>
              </Typography>
            </Button>
            <Button
              component="label"
              onClick={onSavePermit}
              sx={{
                background:
                  'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                boxShadow: 'none',
                ml: 2,
                px: 8,
                py: 2,
              }}
              variant="contained"
            >
              <Typography variant="body2">
                <strong>SAVE</strong>
              </Typography>
            </Button>
          </Box>
        </Container>
      </Box>

      <DeleteModal onDelete={onDeleteFile} />
      <UploadFilesModal
        errorUpload={errorUploadFiles}
        executeRequirements={executeRequirements}
        executeUpload={executeUploadFilesApprover}
      />
    </Container>
  );
};

export default PermitDetails;
