import * as React from 'react';

const EighthPermitIcon = () => {
  return (
    <svg fill="none" height={60} width={72}>
      <path
        d="M7.29 27.478h11.215v5.607h2.804v8.973h8.973l5.046-2.804h35.329L59.442 58.88H6.729L1.122 42.058H7.29v-14.58z"
        fill="#FFF500"
      />
      <path
        d="M71.376 38.406a.948.948 0 00-.403-.1h-3.775v-7.853c0-.755-.604-1.309-1.36-1.309h-3.17v-7.751c0-.755-.604-1.31-1.36-1.31H44.145c-.755 0-1.359.605-1.359 1.31v7.751h-3.17c-.756 0-1.36.604-1.36 1.309v7.802h-2.567c-.05 0-.05 0-5.839 2.87h-7.45v-7.752a.882.882 0 00-.855-.856H19.53v-4.832a.848.848 0 00-.856-.856h-4.882v-3.926c0-.101.05-.202.1-.252L32.97 3.574c.05-.05.15-.05.15 0 0 0 .102.05.102.1V23.206c-1.158.402-1.813 1.61-1.611 2.819.201 1.006.956 1.812 1.963 2.013.755.15 1.51-.05 2.064-.503.604-.453.906-1.158.906-1.913 0-.352-.202-.705-.554-.806-.252-.15-.503-.1-.755.05-.252.152-.352.404-.352.706a.818.818 0 01-.554.755c-.302.1-.604 0-.806-.252-.2-.252-.251-.554-.1-.856.1-.252.402-.403.704-.403a.882.882 0 00.856-.855V.654a.654.654 0 00-.402-.604.623.623 0 00-.705.151L12.332 21.745c-.1.1-.201.252-.201.453v4.63H7.148c-.453.051-.755.404-.755.857v13.49H.856c-.202 0-.453.1-.604.251-.151.1-.252.352-.252.554 0 .1 0 .201.05.252L5.79 59.395c.1.352.453.604.805.604h52.903a.83.83 0 00.755-.453l11.476-20.034c.202-.352.05-.906-.352-1.107zM8.104 32.567V28.54h9.765v4.027H8.104zm0 8.557v-6.896h12.634v6.896H8.104zm45.554-11.98v-7.298h7.298v7.298h-7.298zm4.53 9.06v-7.298h7.299v7.299h-7.3zm-13.59-9.06v-7.298h7.298v7.298h-7.299zm4.53 9.06v-7.298h7.298v7.299h-7.299zm-9.06 0v-7.298h7.298v7.299h-7.299zm29.445 1.762L59.043 58.34H7.199L2.013 42.836H30.201c.05 0 .101 0 .151-.05.05 0 .101-.051.101-.051l5.537-2.769h33.523z"
        fill="#096BEF"
      />
    </svg>
  );
};

export default EighthPermitIcon;
