import { useRecoilValue } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PropTypes, { array } from 'prop-types';
import { useState } from 'react';
import Typography from '@mui/material/Typography';

import { dateFormat } from 'common/utils/dateConvert';
import formState from 'common/data/formState';

import { CarouselModal } from 'common/components/CarouselModal/CarouselModal';

const statusDisplay = {
  Approved: { color: '#2bb673' },
  'In Review': { color: '#096bef' },
  New: { color: '#a3a3a3' },
  Rejected: { color: '#ff0000' },
  Pending: { color: '#ff7a00' },
};

const PermitListsDashboard = ({ data, onSelectPermit }) => {
  const [open, setOpen] = useState(false);
  const formValues = useRecoilValue(formState);
  const statusFormat = statusDisplay[data?.status];
  const displayDate = dateFormat(data?.applicationStartDate);

  const { user } = formValues;
  const isLoggedIn = Boolean(user);
  const businessName = data?.companyDetail?.businessName || '--';
  const permitCategory = data?.permitType?.name || '--';

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Paper elevation={2}>
      <Box
        alignItems="center"
        display="grid"
        gridTemplateColumns="15% 35% 30% 10% 10%"
        p={4}
      >
        <Box sx={{ color: '#010046', textTransform: 'uppercase' }}>
          <Typography sx={{ fontWeight: 400 }} variant="caption">
            {displayDate || '--'}
          </Typography>
        </Box>
        <Box color="#010046">
          <Typography sx={{ fontWeight: 400 }} variant="caption">
            {permitCategory}
          </Typography>
        </Box>
        <Box color="#010046">
          <Typography sx={{ fontWeight: 400 }} variant="caption">
            {businessName}
          </Typography>
        </Box>
        <Box
          sx={{
            color: statusFormat.color,
            textTransform: 'uppercase',
          }}
        >
          <Typography sx={{ fontWeight: 500 }} variant="caption">
            {data?.status || '--'}
          </Typography>
        </Box>
        {data?.documentUrls?.length > 0 && data?.status === 'Approved' ? (
          <Box>
            <Button
              onClick={handleOpen}
              sx={{
                background:
                  'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                px: 5,
              }}
              target="_blank"
              variant="contained"
            >
              VIEW
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              component="button"
              onClick={() => {
                if (isLoggedIn) {
                  onSelectPermit(businessName, permitCategory, data);
                }
              }}
              sx={{
                background:
                  'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
                px: 5,
              }}
              variant="contained"
            >
              VIEW
            </Button>
          </Box>
        )}
      </Box>

      <CarouselModal
        docs={data?.documentUrls}
        onClose={handleClose}
        open={open}
      />
    </Paper>
  );
};

PermitListsDashboard.propTypes = {
  data: PropTypes.shape({
    applicationStartDate: PropTypes.string.isRequired,
    companyDetail: PropTypes.object.isRequired,
    documentUrls: array,
    isLastStep: PropTypes.bool,
    permitApplicationLogId: PropTypes.number.isRequired,
    permitFileUrl: PropTypes.string,
    permitType: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onSelectPermit: PropTypes.func.isRequired,
};

export default PermitListsDashboard;
