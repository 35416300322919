import * as React from 'react';

const ChatLogoIcon = () => {
  return (
    <svg fill="none" height={44} width={44} xmlns="http://www.w3.org/2000/svg">
      <rect fill="url(#chatLogo)" height={44} rx={22} width={44} />
      <path
        d="M29.22 26.092a.892.892 0 0 0-1.203.376c0 .03-1.359 2.506-5.123 2.872v-8.396h3.248a.892.892 0 0 0 0-1.785h-3.248v-2.105a3.093 3.093 0 1 0-1.785 0v2.105h-3.248a.892.892 0 0 0 0 1.785h3.248v8.416c-3.714-.346-5.068-2.767-5.123-2.872a.893.893 0 1 0-1.584.827c.08.145 1.865 3.463 6.707 3.834v.662a.893.893 0 0 0 1.785 0v-.682c4.842-.37 6.626-3.689 6.707-3.834a.894.894 0 0 0-.381-1.203Zm-8.522-12a1.314 1.314 0 1 1 1.303 1.313 1.313 1.313 0 0 1-1.313-1.313h.01Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="chatLogo"
          x1={46.475}
          x2={6.6}
          y1={2.2}
          y2={38.775}
        >
          <stop stopColor="#FFF500" />
          <stop offset={1} stopColor="#FF8A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChatLogoIcon;
