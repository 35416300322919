import { Box, Typography } from '@mui/material';
import { func, string } from 'prop-types';
import { useRecoilState } from 'recoil';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cn from 'classnames';
import React from 'react';

import './styles.scss';
import { usePagination, DOTS } from 'common/utils/usePagination';
import { usePermitsActiveHooks, usePermitsActiveSearchHooks } from 'services';
import tableState from 'common/data/tableState';

const PaginationPermits = ({ searchBusinessName, setLoading }) => {
  const [tableValues, setValues] = useRecoilState(tableState);
  const { executePermitsActive } = usePermitsActiveHooks({ manual: true });
  const { executePermitsSearchActive } = usePermitsActiveSearchHooks({
    manual: true,
  });

  const paginationRange = usePagination({
    currentPage: tableValues?.currentPage,
    totalCount: tableValues?.totalItems,
    siblingCount: 2,
    pageSize: tableValues?.pageSize,
  });

  const fetchItems = async currentPage => {
    try {
      setLoading(true);
      let response;

      if (searchBusinessName) {
        response = await executePermitsSearchActive({
          params: {
            page: currentPage,
            pageSize: 25,
            searchText: searchBusinessName,
          },
        });
      } else {
        response = await executePermitsActive({
          params: {
            page: currentPage,
            pageSize: 25,
          },
        });
      }

      if (response.status === 200) {
        setValues(state => ({
          ...state,
          currentPage: response?.data.currentPage,
          items: response?.data.details,
          pageSize: response?.data.pageSize,
          totalItems: response?.data.totalItems,
          totalPages: response?.data.totalPages,
        }));

        setLoading(false);
      } else {
        setValues(state => ({
          ...state,
          currentPage: 0,
          items: [],
          pageSize: 0,
          totalItems: 0,
          totalPages: 0,
        }));

        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="body1">List of Active Permits</Typography>
      </Box>
      <Box alignItems="center" display="flex">
        {tableValues?.currentPage > 1 && (
          <ArrowBackIosIcon
            onClick={() => fetchItems(tableValues?.currentPage - 1)}
            style={{
              cursor: 'pointer',
            }}
          />
        )}

        {(paginationRange || []).map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <Box key={index}>
                <Typography variant="body1">...</Typography>
              </Box>
            );
          }

          return (
            <Box
              className={cn('PaginationPermits_item', {
                selected: pageNumber === tableValues?.currentPage,
              })}
              key={index}
              onClick={() => fetchItems(pageNumber)}
            >
              <Typography variant="body1">{pageNumber}</Typography>
            </Box>
          );
        })}

        {tableValues?.currentPage !== tableValues?.totalPages && (
          <ArrowForwardIosIcon
            onClick={() => fetchItems(tableValues?.currentPage + 1)}
            style={{
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
      <Box>
        <Typography variant="body1">
          {tableValues?.currentPage} / {tableValues.totalPages}
        </Typography>
      </Box>
    </Box>
  );
};

PaginationPermits.propTypes = {
  searchBusinessName: string.isRequired,
  setLoading: func.isRequired,
};

export default PaginationPermits;
