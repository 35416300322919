import { useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Avatar,
  Box,
  Container,
  Button,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';

import './styles.scss';
import CloseIcon from 'svg/Common/CloseIcon';
import formState from 'common/data/formState';
import NavigationIcon from 'svg/Common/NavigationIcon';
import NavigationLinks from './NavigationLinks';
import VerticalIcon from 'svg/Common/VerticalIcon';

import t from 'common/utils/translates';
import u from 'common/utils/urls';

const linksGroups = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Permits',
    url: '/permits-list',
  },
  {
    name: 'Forms',
    menus: [
      {
        name: 'Annual Vehicle Pass',
        url: u.avpPdf,
        hasFile: true,
      },
      {
        name: 'Annual Personnel Pass',
        url: u.appXls,
        hasFile: true,
      },
    ],
  },
  {
    name: 'Payment Options',
    menus: [
      {
        name: 'GCash',
        url: '/gcash-payment',
      },
    ],
  },
];

const linksGroupsApplicant = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Permits',
    url: '/permits',
  },
  {
    name: 'Forms',
    menus: [
      {
        name: 'Annual Vehicle Pass',
        url: u.avpPdf,
        hasFile: true,
      },
      {
        name: 'Annual Personnel Pass',
        url: u.appXls,
        hasFile: true,
      },
    ],
  },
  {
    name: 'Payment Options',
    menus: [
      {
        name: 'GCash',
        url: '/gcash-payment',
      },
    ],
  },
];

const NavigationBar = () => {
  const formValues = useRecoilValue(formState);
  const [isScrolled, setScrolled] = useState(false);
  const [popupMenu, setMenuPopup] = useState(null);
  const [popupUser, setUserPopup] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const { userType, user } = formValues;

  useEffect(() => {
    const handleScroll = () => {
      const offset = global.scrollY;

      if (offset > 96) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    if (!isScrolled) {
      global.addEventListener('scroll', handleScroll);
    } else {
      global.removeEventListener('scroll', handleScroll);
    }

    return () => global.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  useEffect(() => {
    if (!isMobile && popupMenu) {
      setMenuPopup(null);
    }
  }, [isMobile, popupMenu, setMenuPopup]);

  return (
    <Container
      className={cn('NavigationBar_header', { isScrolled })}
      disableGutters
      maxWidth={false}
    >
      <Box className="NavigationBar_section">
        <Box className="NavigationBar_logo">
          <img alt="logo" height="48" src={u.logoImg} width="48" />
          {!isMobile && (
            <Box color="#096bef" ml={2}>
              <Typography variant="h6">{t.navigation.title}</Typography>
            </Box>
          )}
        </Box>

        {!isMobile && (
          <>
            {!user && (
              <>
                <Box display="flex">
                  {(linksGroups || []).map(({ menus, name, url }) => (
                    <NavigationLinks
                      key={name}
                      menus={menus}
                      name={name}
                      selectedLink={location.pathname}
                      url={url}
                    />
                  ))}
                </Box>
                <Box display="flex" justifyContent="flex-end" width="30%">
                  <Button
                    href={process.env.REACT_APP_LOGIN}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    <Typography variant="body1">
                      {t.navigation.login}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}

            {user && (
              <>
                {userType === 'applicant' && (
                  <Box display="flex">
                    {(linksGroupsApplicant || []).map(
                      ({ menus, name, url }) => (
                        <NavigationLinks
                          key={name}
                          menus={menus}
                          name={name}
                          selectedLink={location.pathname}
                          url={url}
                        />
                      )
                    )}
                  </Box>
                )}
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="flex-end"
                  width="30%"
                >
                  <Button
                    aria-controls="popupUser"
                    aria-expanded={popupUser ? 'true' : undefined}
                    aria-haspopup="true"
                    className="NavigationBar_user"
                    id="button"
                    onClick={event => setUserPopup(event.currentTarget)}
                  >
                    <Box
                      color="#010046"
                      mr={1}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      <Typography variant="body1">
                        {user?.given_name.concat(' ', user?.family_name) || ''}
                      </Typography>
                    </Box>
                    <Avatar src={u.userImg} sx={{ width: 24, height: 24 }} />
                    <ArrowDropDownIcon sx={{ color: '#096bef' }} />
                  </Button>

                  <Menu
                    anchorEl={popupUser}
                    id="popupUser"
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    onClose={() => setUserPopup(null)}
                    open={Boolean(popupUser)}
                  >
                    <MenuItem
                      onClick={() => {}}
                      sx={{
                        color: '#000000',
                      }}
                    >
                      <Typography variant="button">
                        {t.navigation.editProfile}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      className="NavigationBar_logout"
                      onClick={() => {
                        localStorage.removeItem('token');
                        global.location = '/';
                      }}
                    >
                      <Typography
                        sx={{
                          borderBottom: '2px solid #096bef',
                          color: '#096bef',
                        }}
                        variant="button"
                      >
                        {t.navigation.logout}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </>
        )}

        {isMobile && (
          <>
            {!user && (
              <Box color="#096bef">
                <Typography variant="h6">{t.navigation.title}</Typography>
              </Box>
            )}

            {user && (
              <Box
                color="#010046"
                sx={{ display: 'flex', textTransform: 'capitalize' }}
              >
                <Typography variant="body1">
                  {user?.given_name.concat(' ', user?.family_name) || ''}
                </Typography>
                <Avatar src={u.userImg} sx={{ ml: 1, width: 24, height: 24 }} />
              </Box>
            )}

            <Box alignItems="center" display="flex">
              <Box mr={2}>
                <VerticalIcon />
              </Box>
              {!popupMenu ? (
                <Box onClick={event => setMenuPopup(event.currentTarget)}>
                  <NavigationIcon />
                </Box>
              ) : (
                <Box onClick={() => setMenuPopup(null)}>
                  <CloseIcon />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>

      <SwipeableDrawer
        anchor="top"
        classes={{ paper: 'NavigationBar_swipeableDrawer_root' }}
        disableBackdropTransition
        id="popupMenu"
        onClose={() => setMenuPopup(null)}
        onOpen={event => setMenuPopup(event.currentTarget)}
        open={Boolean(popupMenu)}
        variant="persistent"
      >
        <Box className="NavigationBar_drawerLinks">
          {userType === 'applicant' && (
            <>
              {(linksGroups || []).map(({ menus, name, url }) => (
                <NavigationLinks
                  key={name}
                  menus={menus}
                  name={name}
                  selectedLink={location.pathname}
                  url={url}
                />
              ))}
            </>
          )}

          {user && (
            <>
              <Box my={2}>
                <Typography variant="button">
                  {t.navigation.editProfile}
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  localStorage.removeItem('token');
                  global.location = '/';
                }}
              >
                <Typography variant="button">{t.navigation.logout}</Typography>
              </Box>
            </>
          )}

          {!user && (
            <Box
              onClick={() => {
                global.location.href = process.env.REACT_APP_LOGIN;
              }}
            >
              <Typography variant="button">{t.navigation.login}</Typography>
            </Box>
          )}
        </Box>
      </SwipeableDrawer>
    </Container>
  );
};

export default NavigationBar;
