const date = new Date();

const translates = {
  admin: {
    approverSteps: {
      approved: approvedBy => `Completed By: ${approvedBy}`,
      approvedT: `Completed By:`,
    },
  },
  applicant: {
    begin: 'Choose the type of permit you need to begin your application.',
    getHassle: 'Apply for a permit online and experience hassle-free approval.',
    publicPermit: {
      active: 'ACTIVE PERMITS',
      clear: 'CLEAR',
      search: 'Search business name',
    },
  },
  chat: {
    title: 'CPA',
    write: 'Write a message',
  },
  errors: {
    error: 'Error!',
  },
  footer: {
    copyright: `Copyright © ${date.getFullYear()}. All Rights Reserved Cebu Port Authority`,
    cpa: 'CEBU PORT AUTHORITY',
  },
  navigation: {
    editProfile: 'EDIT PROFILE',
    login: 'Log In',
    logout: 'Log Out',
    title: 'CEBU PORT AUTHORITY',
  },
};

export default translates;
