import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import cn from 'classnames';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import './styles.scss';
import formState from 'common/data/formState';
import TabsSection from '../TabsSection';

const StepIconComponent = ({ active, completed, icon }) => {
  return (
    <Box
      className={cn('SubSteps_icon', {
        isActive: active,
        isCompleted: completed,
      })}
    >
      <Typography variant="h6">{icon}</Typography>
    </Box>
  );
};

StepIconComponent.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
};

const SubSteps = ({ currentStep, step, subSteps }) => {
  const [values, setValues] = useRecoilState(formState);
  const { activeSubStep, permitSubSteps } = values;
  const findSubStep = (subSteps || []).find(
    ({ permitApplicationStepId }) =>
      permitApplicationStepId === currentStep?.permitApplicationStepId
  );

  const currentSubStep =
    currentStep && findSubStep ? findSubStep.orderPriority - 1 : 0;

  const onTabChange = tabCategory => {
    const listApplicant = (subSteps || []).filter(
      ({ category }) => category === tabCategory
    );

    if (listApplicant.length !== 0) {
      setValues(state => ({
        ...state,
        activeSubStep: listApplicant[0]?.permitApplicationStepId,
        activeTab: tabCategory === 'New Applicant' ? 0 : 1,
        permitSubSteps: listApplicant,
      }));
    } else {
      setValues(state => ({
        ...state,
        activeSubStep: step?.permitApplicationStepId,
        permitSubSteps: listApplicant,
      }));
    }
  };

  useEffect(() => {
    if (currentStep) {
      const applicant = (subSteps || []).find(
        ({ permitApplicationStepId }) =>
          permitApplicationStepId === currentStep?.permitApplicationStepId
      );

      const listApplicant = (subSteps || []).filter(
        ({ category }) => category === applicant?.category
      );

      setValues(state => ({
        ...state,
        activeSubStep: currentStep?.permitApplicationStepId,
        activeTab: applicant?.category === 'New Applicant' ? 0 : 1,
        permitSubSteps: listApplicant,
      }));
    } else {
      const listApplicant = (subSteps || []).filter(
        ({ category }) => category === 'New Applicant'
      );

      if (listApplicant.length !== 0) {
        setValues(state => ({
          ...state,
          activeSubStep: listApplicant[0]?.permitApplicationStepId,
          activeTab: 0,
          permitSubSteps: listApplicant,
        }));
      } else {
        setValues(state => ({
          ...state,
          activeSubStep: step?.permitApplicationStepId,
          activeTab: 0,
          permitSubSteps: listApplicant,
        }));
      }
    }
  }, [currentStep, setValues, step, subSteps]);

  return (
    <Container disableGutters maxWidth="lg">
      <Box display="flex" flexDirection="column">
        <Box alignItems="center" display="flex" flexDirection="row">
          <Box bgcolor="#096bef" borderRadius={1} color="#ffffff" px={1}>
            <Typography variant="h6">{step?.orderPriority}</Typography>
          </Box>
          <Box color="#010046" ml={2}>
            <Typography sx={{ textTransform: 'uppercase' }} variant="body1">
              {step?.description}
            </Typography>
          </Box>
        </Box>

        {permitSubSteps.length > 1 && (
          <Box display="flex" justifyContent="center" py={2}>
            <Stepper activeStep={currentSubStep} sx={{ width: 400 }}>
              {(permitSubSteps || []).map(({ permitApplicationStepId }) => (
                <Step
                  classes={{ root: 'SubSteps_stepRoot' }}
                  key={permitApplicationStepId}
                >
                  <StepLabel
                    classes={{ iconContainer: 'SubSteps_iconContainer' }}
                    StepIconComponent={StepIconComponent}
                  />
                </Step>
              ))}
            </Stepper>
          </Box>
        )}

        {activeSubStep && <TabsSection onTabChange={onTabChange} step={step} />}
      </Box>
    </Container>
  );
};

SubSteps.propTypes = {
  currentStep: PropTypes.object,
  step: PropTypes.object,
  subSteps: PropTypes.array,
};

SubSteps.defaultProps = {
  step: undefined,
  susSteps: undefined,
};

export default SubSteps;
